import { baseUrl } from 'constants';

export const checkEmail = async (email) => {
  const res = await fetch(`https://email-checker.p.rapidapi.com/verify/v1?email=${email}`, {
    method: 'GET',
    headers: {
      'X-RapidAPI-Key': 'bd8ff0ac78mshf00399f7f416983p126571jsne279ce099d1e',
      'X-RapidAPI-Host': 'email-checker.p.rapidapi.com',
    },
  });
  const response = await res.json();
  return response;
};

export const getConfig = async (isTest) => {
  const res = await fetch(`${baseUrl}/config${isTest ? '?test=1' : ''}`);
  const response = await res.json();
  return response;
};

export const createCustomerAndSubscription = async (email, priceId, isTest) => {
  const res = await fetch(`${baseUrl}/create-customer-and-subscription`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      priceId,
      test: isTest,
    }),
  });

  const response = await res.json();
  return response;
};

export const getLeaksData = async (email) => {
  const url = `https://haveibeenpwned.com/api/v3/breachedaccount/${email || ''}?truncateResponse=false`;

  const res = await fetch(
    `https://driver-updater.herokuapp.com/api/v1/front/proxyquery/?service_for_my_benefits=haveibeenpwned.com&url=${btoa(
      url,
    )}`,
  );
  const response = await res.json();
  return response;
};

export const getGoogleUserInfo = async (data) => {
  const response = await fetch('https://www.googleapis.com/oauth2/v3/userinfo', {
    headers: { Authorization: `Bearer ${data.access_token}` },
  });
  const userInfo = await response.json();
  return userInfo;
};
