export const priceData = [
  {
    id: 'weekly',
    title: '1-week',
    oldPrice: '$17.77',
    currentPrice: '$6.99',
    perDay: { old: '$2.54', current: '$0.99' },
    fullPrice: '$6.99 every week',
  },
  {
    id: 'monthly',
    title: '4-week',
    oldPrice: '$38.95',
    currentPrice: '$19.99',
    perDay: { old: '$1.30', current: '$0.66' },
    isPopular: true,
    fullPrice: '$19.99 every month',
  },
  {
    id: '3monthly',
    title: '12-week',
    oldPrice: '$119.99',
    currentPrice: '$69.99',
    perDay: { old: '$2.54', current: '$0.77' },
    fullPrice: '$69.99 every 3 months',
  },
];

export const sale = {
  old: '33%',
  current: '51%',
};
