import { useContext, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ConfigsContext } from 'App';
import { Overlay, OverlayClose } from 'components/ui';
import { URL_PARAMS } from 'constants';
import paypalSrc from 'assets/paymentMethods/pay-pal-title.svg';

const PayButton = styled.button`
  height: 47px;
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  border-radius: 52px;
  margin-top: 20px;
  background-color: #ffc43a;
  border: none;
  cursor: pointer;
`;

const OverlayCustom = styled(Overlay)`
  width: ${({ $isHidden }) => ($isHidden ? 0 : '100%')};
  height: ${({ $isHidden }) => ($isHidden ? 0 : '100%')};
  opacity: ${({ $isHidden }) => ($isHidden ? 0 : '1')};
  overflow: hidden;
  & > div {
    position: relative;
    max-width: 500px;
    height: 85%;
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    overflow-y: auto;
  }
  .paypal-checkout {
    height: 100%;
  }
`;

const PaypalButton = ({ priceId, email }) => {
  const [isOpened, setIsOpened] = useState(false);
  const { paddle, paddleStatus } = useContext(ConfigsContext);
  const [searchParams] = useSearchParams();
  const countryCode = searchParams.get(URL_PARAMS.COUTNRY_CODE)?.toUpperCase();

  useEffect(() => {
    if (paddleStatus === 'checkout.closed') {
      setIsOpened(false);
    }
  }, [paddleStatus]);

  const handleClick = () => {
    try {
      paddle?.Checkout.open({
        items: [{ priceId }],
        customer: {
          email,
          address: {
            countryCode,
          },
        },
        settings: {
          allowedPaymentMethods: ['paypal'],
          frameTarget: 'paypal-checkout',
          displayMode: 'inline',
          frameStyle: 'width: 100%; max-width: 100%; min-width: 300px; height: 100%;',
        },
      });
      setIsOpened(true);
    } catch (e) {
      console.error('Paddle checkout open with button error: ', e);
      setIsOpened(false);
    }
  };

  const closeCheckout = () => {
    setIsOpened(false);
    paddle?.Checkout.close();
  };

  return (
    <>
      <PayButton onClick={handleClick}>
        <img src={paypalSrc} alt="paypal-icon" width={58} />
      </PayButton>
      <OverlayCustom $isHidden={!isOpened}>
        <div>
          <OverlayClose onClick={closeCheckout}>close</OverlayClose>
          <div className="paypal-checkout"></div>
        </div>
      </OverlayCustom>
    </>
  );
};

export default PaypalButton;
