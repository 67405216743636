import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const ANIMATION_DURATION = 1000;

const NumberSpan = styled.span`
  position: relative;
  display: flex;
  height: 16px;
  overflow: hidden;
`;

const AnimatedAddN = styled.span`
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: #37a9fb;
  color: #ffffff;
  z-index: 1;
  transform: ${({ $positionY }) => `translateY(-${$positionY * 20}px)`};
  transition: ${({ $isAnimate }) => ($isAnimate ? `transform ${ANIMATION_DURATION}ms` : 'none')};
  span {
    height: 16px;
  }
`;

const NumberAnimated = ({ position }) => {
  const [value, setValue] = useState(position);
  const [isAnimate, setIsAnimate] = useState(true);

  useEffect(() => {
    if (position < value) {
      setValue(10);
    } else {
      setValue(position);
    }
  }, [position]);

  useEffect(() => {
    if (value === 10) {
      setTimeout(() => {
        setIsAnimate(false);
      }, ANIMATION_DURATION);
    } else if (value === 0) {
      setTimeout(() => {
        setIsAnimate(true);
        if (value !== position) {
          setValue(position);
        }
      }, 50);
    }
  }, [value]);

  useEffect(() => {
    if (!isAnimate) {
      setValue(() => 0);
    }
  }, [isAnimate]);

  return (
    <AnimatedAddN $positionY={value} $isAnimate={isAnimate}>
      <span>0</span>
      <span>1</span>
      <span>2</span>
      <span>3</span>
      <span>4</span>
      <span>5</span>
      <span>6</span>
      <span>7</span>
      <span>8</span>
      <span>9</span>
      <span>0</span>
    </AnimatedAddN>
  );
};

const Counter = ({ defaultCount, maxToAdd }) => {
  const [counter, setCounter] = useState(defaultCount);
  const initialDelay = useRef(500);

  useEffect(() => {
    const delay = initialDelay.current || 2000;
    const intervalId = setInterval(() => {
      const toAdd = Math.floor(Math.random() * maxToAdd);
      if (toAdd) {
        setCounter(counter + toAdd);
      }
    }, delay);
    return () => {
      initialDelay.current = false;
      localStorage.setItem('userCount', counter);
      clearInterval(intervalId);
    };
  }, [counter]);

  const counterToRender = () => {
    const str = counter.toString();
    const result = [];
    let arrayId = Math.ceil(str.length / 3) - 1;

    for (let i = str.length - 1; i >= 0; i--) {
      if (result[arrayId]?.length === 3) {
        arrayId = arrayId - 1;
      }
      if (!result[arrayId]) {
        result[arrayId] = str[i];
      } else {
        result[arrayId] = `${str[i]}${result[arrayId]}`;
      }
    }
    return result.join(' ');
  };

  const counterString = counterToRender();

  const getDigitArray = () => {
    const digitArray = Array(counterString.length).fill('');
    return digitArray;
  };
  return (
    <NumberSpan>
      +
      {getDigitArray().map((_, i) => {
        if (counterString[i] === ' ') {
          return <span key={i}>&nbsp;</span>;
        }
        return <NumberAnimated key={i} position={+counterString[i]} />;
      })}
    </NumberSpan>
  );
};

export default Counter;
