import { useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import ErrorIcon from '@mui/icons-material/Error';
import { Alert, Snackbar } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Loader from 'components/loader';
import { Container, Title, TextPositioned, Button } from 'components/ui';
import { Radar } from 'assets/radar';
import { eventParams, sendPostBack } from 'api/analyticsHelper';
import { checkEmail } from 'api/api';
import GoogleLoginBlock from './googleLogin';
import { ROUTES_NAMES, URL_PARAMS } from 'constants';

export const Logo = styled.div`
  border-radius: 12px;
  background: #ebf4ff;
  padding: 14px 12px;
  margin-bottom: 26px;
  align-self: center;
  line-height: 0;
`;

export const EmailInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

export const CustomizedTextInput = styled(TextField)`
  .MuiOutlinedInput-root {
    font-family: inherit;
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: #accaf6;
    }
  }
  .MuiInputBase-input {
    padding: 20px 16px;
    font-size: 0.9rem;
  }
  .MuiInputBase-input::placeholder {
    color: #8b919a;
    opacity: 1;
  }
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid #accaf6;
    border-radius: 8px;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #accaf6;
  }
`;

export const SnackBtn = styled.div`
  margin-right: 12px;
`;

export const Law = styled.div`
  color: #8b919a;
  font-size: 0.67rem;
  line-height: 1rem;
  margin-top: auto;
  & a {
    color: inherit;
    cursor: pointer;
  }
`;

const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const EnterEmail = () => {
  const { email, onEmailChange, incrementStep } = useOutletContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailErr, setIsEmailErr] = useState(false);
  const [emailCheckMessage, setEmailCheckMessage] = useState(false);
  const [isOpenSnack, setIsOpenSnack] = useState(false);
  const [searchParams] = useSearchParams();

  const handleValue = (emailValue) => {
    setIsEmailErr(false);
    onEmailChange(emailValue);
  };

  const checkEmailIsValid = async () => {
    if (!(email && email.length > 0 && emailReg.test(email))) {
      setEmailCheckMessage('Email address is invalid. Please correct and try again.');
      return false;
    }

    setIsLoading(true);
    try {
      const response = await checkEmail(email);
      if (response.status === 'valid') {
        setEmailCheckMessage('');
        return true;
      }
      setEmailCheckMessage("Email address doesn't exist. Please correct and try again.");
    } catch (e) {
      console.error('Email checker error: ', e);
    }
    setIsLoading(false);
    return false;
  };

  const handleContinue = async () => {
    const isValidEmail = await checkEmailIsValid();

    if (!isValidEmail) {
      setIsEmailErr(true);
      setIsOpenSnack(true);
      return;
    }

    setIsLoading(true);

    sendPostBack(eventParams.EMAIL_ENTERED, searchParams.get(URL_PARAMS.CLICK_ID));

    localStorage.setItem('email', email);
    localStorage.setItem('savedTime', new Date().getTime());

    setTimeout(() => {
      setIsEmailErr(false);
      setIsLoading(false);
      return incrementStep();
    }, 500);

    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <Loader text="Processing..." isFixed={true} />}

      <Container>
        <Snackbar
          open={isOpenSnack}
          style={{
            top: 8,
            right: 24,
            bottom: 'auto',
          }}
        >
          <Alert
            onClose={() => {
              setIsOpenSnack(false);
            }}
            severity="error"
            className="snackbar"
            icon={<ErrorIcon />}
            action={
              <SnackBtn
                onClick={() => {
                  setIsOpenSnack(false);
                }}
              >
                Dismiss
              </SnackBtn>
            }
          >
            {emailCheckMessage}
          </Alert>
        </Snackbar>
        <Logo>
          <Radar />
        </Logo>
        <Title>
          What email address do you <br /> want to scan?
        </Title>
        <TextPositioned>
          Run a free scan and check for personal data leaks. Type in your email to register and start your scan.
        </TextPositioned>

        <EmailInput>
          <CustomizedTextInput
            placeholder="example@gmail.com"
            variant="outlined"
            size="normal"
            type="email"
            value={email}
            onChange={(e) => handleValue(e.target.value)}
            error={isEmailErr}
            helperText={isEmailErr && 'incorrect email'}
            InputProps={{
              endAdornment: isEmailErr && (
                <InputAdornment position="start">
                  <ErrorIcon style={{ color: '#E57373' }} />
                </InputAdornment>
              ),
            }}
          />
        </EmailInput>

        <Button onClick={handleContinue}>Start Free Scan</Button>

        <GoogleLoginBlock
          onSuccess={(emailValue) => {
            if (!emailValue) {
              console.error('Google login callback error');
              return;
            }
            onEmailChange(emailValue);
          }}
        ></GoogleLoginBlock>

        <Law style={{ paddingBottom: '16px' }}>
          By signing up, you agree to our{' '}
          <a target="_blank" rel="noreferrer" href={`/${ROUTES_NAMES.TERMS}`}>
            Terms of Service
          </a>{' '}
          and{' '}
          <a target="_blank" rel="noreferrer" href={`/${ROUTES_NAMES.PRIVACY}`}>
            Privacy Policy
          </a>
          . You also agree to receive product-related marketing emails from GuardPRO, which you can unsubscribe from at
          any time.
        </Law>
      </Container>
    </>
  );
};

export default EnterEmail;
