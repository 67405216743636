import styled from 'styled-components';
import { Section } from '../../ui';
import bug from './assets/bug.svg';
import incognito from './assets/incognito.svg';
import uniq from './assets/uniq.svg';
import validation from './assets/validation.svg';

const Block = styled(Section)`
  padding: 16px;
  border-radius: 12px;
  background-color: #1e202b;
  position: relative;
  &::after {
    content: '';
    width: calc(100% - 32px);
    height: 1px;
    position: absolute;
    top: 50%;
    left: 16px;

    background-color: #323648;
  }
  &::before {
    content: '';
    height: calc(100% - 32px);
    width: 1px;
    position: absolute;
    top: 16px;
    left: 50%;
    background-color: #323648;
  }
`;

const BlockRow = styled.div`
  display: flex;
  &:first-child {
    padding-top: 14px;
    padding-bottom: 25px;
  }
  &:last-child {
    padding-top: 23px;
    padding-bottom: 14px;
  }
`;

const BlockItem = styled.div`
  width: 50%;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  &:last-child {
    padding-left: 20px;
  }
`;

const Picture = styled.img``;
const Title = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  color: #ffffff;
`;
const Text = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  color: #8f94a8;
`;

const FeatureAnalysed = () => {
  return (
    <Block>
      <BlockRow>
        <BlockItem>
          <Picture src={validation} />
          <div>
            <Text>Password leak</Text>
            <Title>High level</Title>
          </div>
        </BlockItem>
        <BlockItem>
          <Picture src={incognito} />
          <div>
            <Text>Data leak</Text>
            <Title>Average</Title>
          </div>
        </BlockItem>
      </BlockRow>
      <BlockRow>
        <BlockItem>
          <Picture src={uniq} />
          <div>
            <Text>Unique password</Text>
            <Title>No unique</Title>
          </div>
        </BlockItem>
        <BlockItem>
          <Picture src={bug} />
          <div>
            <Text>Risk level</Text>
            <Title>8/10</Title>
          </div>
        </BlockItem>
      </BlockRow>
    </Block>
  );
};

export default FeatureAnalysed;
