const FunnelScreenId = {
  regularity: 'regularity',
  infoRegularity: 'infoRegularity',
  dataBreaches: 'dataBreaches',
  uniqPasswords: 'uniqPasswords',
  infoUniqPasswords: 'infoUniqPasswords',
  storePasswords: 'storePasswords',
  serviceChoose: 'serviceChoose',
  reportsRegularity: 'reportsRegularity',
  precautions: 'precautions',
  notifications: 'notifications',
  suspiciousEmails: 'suspiciousEmails',
  infoSuspiciousEmails: 'infoSuspiciousEmails',
  protectMeasures: 'protectMeasures',
  publickWifi: 'publickWifi',
  infoPublickWifi: 'infoPublickWifi',
  createAccount: 'createAccount',
  softUpdates: 'softUpdates',
  securityBreach: 'securityBreach',
  securityDevice: 'securityDevice',
  personalProtect: 'personalProtect',
  publicProtect: 'publicProtect',
  downloadsProtect: 'downloadsProtect',
  preferNotifications: 'preferNotifications',
};

export default FunnelScreenId;
