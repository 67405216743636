import styled from 'styled-components';
import star from 'assets/icon-star-full.svg';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Rating = styled.div`
  display: flex;
  gap: 3px;
`;

const Name = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
`;

const ReviewTitle = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 600;
  margin-bottom: 2px;
  color: #ffffff;
`;

const ReviewText = styled.div`
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 2px;
`;

const ReviewItemBlock = styled.div`
  padding: 16px;
  border-radius: 16px;
  background-color: ${({ $theme }) => ($theme === 'dark' ? '#1a1c28' : '#ffffff')};
  color: ${({ $theme }) => ($theme === 'dark' ? '#babdc9' : '#5D6572')};
  margin-bottom: 12px;
  &:last-child {
    margin-bottom: 0;
  }
  ${ReviewTitle} {
    color: ${({ $theme }) => ($theme === 'dark' ? '#ffffff' : '#172335')};
  }
`;

const ReviewItem = ({ name, title, text, rating, theme = 'dark' }) => {
  return (
    <ReviewItemBlock $theme={theme}>
      <Header>
        <Rating>
          {rating.map((_, j) => (
            <img src={star} alt="star icon" key={j} />
          ))}
        </Rating>
        <Name>{name}</Name>
      </Header>
      <ReviewTitle>{title}</ReviewTitle>
      <ReviewText>{text}</ReviewText>
    </ReviewItemBlock>
  );
};

export default ReviewItem;
