import { memo, useEffect, useRef, useState, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CardNumberElement, CardCvcElement, CardExpiryElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import PaymentMethodButtons from '../paymentMethodButtons';
import PaypalButton from '../paypalButton';
import { Radio } from '../../../routes/choosePlan/ui';
import { ButtonPayment } from 'components/ui';
import Loader from 'components/loader';
import { createCustomerAndSubscription } from 'api/api';
import { ConfigsContext } from 'App';
import amex from 'assets/paymentMethods/amex.svg';
import discover from 'assets/paymentMethods/discover.svg';
import masterCard from 'assets/paymentMethods/master-card.svg';
import visa from 'assets/paymentMethods/visa.svg';
import card from 'assets/paymentMethods/card.svg';
import paypal from 'assets/paymentMethods/pay-pal.svg';
import tick from 'assets/icon-tick.svg';
import { URL_PARAMS, ROUTES_NAMES } from 'constants';

const PaymentFormBlock = styled.div`
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
  line-height: 21px;
  background-color: #242738;
  border-radius: 16px;
  scroll-margin: 80px;
`;

const ItemContent = styled.div`
  position: relative;
  height: ${({ $isOpened, $height }) => ($isOpened ? `${$height}px` : '0')};
  overflow: hidden;
  transition: height 0.2s;
  & > div {
    padding-bottom: 20px;
  }
`;

const Separator = styled.hr`
  width: 100%;
  height: 1px;
  margin: 0 auto;
  background-color: #323648;
  border: none;
`;

const ItemHeader = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const RadioPositioned = styled(Radio)`
  margin-right: 12px;
`;

const ItemTitle = styled.span`
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
`;

const ItemCol = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  img {
    width: 26px;
    height: 20px;
  }
`;

const ItemRow = styled.div`
  display: flex;
  gap: 16px;
  margin: 16px 0;
  & > div {
    width: 50%;
  }
`;
const ItemText = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  margin: 16px auto 0;
  max-width: 200px;
  text-align: center;
`;

const InputsContainer = styled.div`
  scroll-margin: 70px;
`;

const InputLabel = styled.label`
  display: block;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const InputWrapper = styled.div`
  padding: 11px;
  width: 100%;
  min-height: 40px;

  border: ${({ isError }) => (isError ? '1px solid #f44336' : '1px solid #81859C')};
  border-radius: 4px;

  outline: none;
  background-color: transparent;
  color: #ffffff;
  &:last-child {
    margin-bottom: 0;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    border-color: #80bdff;
  }
`;

const CardInputWrapper = styled(InputWrapper)`
  padding-left: 40px;
  background-image: url(${card});
  background-position: 11px 14px;
  background-size: 20px 12px;
  background-repeat: no-repeat;
`;

const ListItem = styled.li`
  list-style: none;
  padding-left: 30px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  background-image: url(${tick});
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 14px 16px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ErrorMessage = styled.div`
  color: #f44336;
  font-size: 14pxrem;
  min-height: 16px;
  margin: 8px 0;
  text-align: center;
`;

const secureText = 'Your payment information is secure with SSL/TLS encryption';

const PaymentFormFields = memo(({ email, incrementStep, tariffId }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [message, setMessage] = useState(null);
  const [currentItem, setCurrentItem] = useState('card');
  const [heights, setHeights] = useState([]);

  const [searchParams] = useSearchParams();

  const ref = useRef();
  const cardContentRef = useRef();
  const paypalContentRef = useRef();

  const { config } = useContext(ConfigsContext);

  const stripe = useStripe();
  const elements = useElements();

  const stripePriceId = config.stripe.prices[tariffId];

  useEffect(() => {
    const heightsUpdated = [];
    if (cardContentRef.current) {
      heightsUpdated[0] = cardContentRef.current.querySelector('div').clientHeight;
    }
    if (paypalContentRef.current) {
      heightsUpdated[1] = paypalContentRef.current.querySelector('div').clientHeight;
    }
    setHeights(heightsUpdated);
  }, []);

  useEffect(() => {
    if (cardContentRef.current) {
      setHeights((oldHeights) => [cardContentRef.current.querySelector('div').clientHeight, oldHeights[1]]);
    }
  }, [message]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsProcessing(true);
    const card = elements.getElement('cardNumber'); //old 'card'

    try {
      const { error /* token */ } = await stripe.createToken(card);
      if (error) {
        setMessage(error.message);
        setIsProcessing(false);

        return;
      }
    } catch (error) {
      if (error) {
        console.error(error.message);
        setIsProcessing(false);

        return;
      }
    }

    const { type, clientSecret } = await createCustomerAndSubscription(
      email,
      stripePriceId,
      !!searchParams.get(URL_PARAMS.TEST),
    );

    const confirmIntent = type === 'setup' ? stripe.confirmCardSetup : stripe.confirmCardPayment;

    const { error: subError /* setupIntent */ } = await confirmIntent(clientSecret, {
      payment_method: {
        card,
      },
    });

    if (subError) {
      setMessage(subError.message);
      setIsProcessing(false);
    } else {
      setIsProcessing(false);
      incrementStep(ROUTES_NAMES.SUCCESS);
    }
  };

  const setActiveItem = (itemId) => {
    setCurrentItem(itemId);
  };
  const cardElementStyles = {
    style: {
      base: {
        color: '#ffffff',
        '::placeholder': {
          color: '#62687E',
        },
      },
    },
  };

  const isCardActive = currentItem === 'card';

  return (
    <>
      <PaymentFormBlock>
        <PaymentMethodButtons priceId={stripePriceId} email={email} incrementStep={incrementStep} />
        <div id="card">
          <ItemHeader onClick={() => setActiveItem('card')}>
            <ItemCol>
              <RadioPositioned $isActive={isCardActive} $isSmall={true} />
              <ItemTitle>Credit Card</ItemTitle>
            </ItemCol>
            <ItemCol>
              <img src={amex} alt="ae-icon" />
              <img src={masterCard} alt="master-card-icon" />
              <img src={visa} alt="visa-icon" />
              <img src={discover} alt="discover-icon" />
            </ItemCol>
          </ItemHeader>
          <ItemContent $isOpened={isCardActive} ref={cardContentRef} $height={heights[0]}>
            <div>
              <InputsContainer ref={ref}>
                <InputLabel>Card Number</InputLabel>
                <CardInputWrapper>
                  <CardNumberElement
                    isError={!!message}
                    options={cardElementStyles}
                    onChange={() => {
                      setMessage(null);
                    }}
                  />
                </CardInputWrapper>

                <ItemRow>
                  <div>
                    <InputLabel>Expiry date</InputLabel>
                    <InputWrapper>
                      <CardExpiryElement
                        isError={!!message}
                        options={cardElementStyles}
                        onChange={(e) => {
                          setMessage(null);
                        }}
                      />
                    </InputWrapper>
                  </div>
                  <div>
                    <InputLabel>CVC</InputLabel>
                    <InputWrapper>
                      <CardCvcElement
                        id="cvc"
                        isError={!!message}
                        options={cardElementStyles}
                        onChange={() => {
                          setMessage(null);
                        }}
                      />
                    </InputWrapper>
                  </div>
                </ItemRow>

                {message && <ErrorMessage>{message}</ErrorMessage>}
              </InputsContainer>
              <ButtonPayment
                id="submit-btn"
                type="submit"
                disabled={message}
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                Pay
              </ButtonPayment>
              <ItemText>{secureText}</ItemText>
            </div>
          </ItemContent>
        </div>
        <Separator />
        <div>
          <ItemHeader onClick={() => setActiveItem('paypal')}>
            <ItemCol>
              <RadioPositioned $isActive={!isCardActive} $isSmall={true} />
              <ItemTitle>PayPal</ItemTitle>
            </ItemCol>
            <ItemCol>
              <img src={paypal} alt="paypal icon" />
            </ItemCol>
          </ItemHeader>
          <ItemContent $isOpened={!isCardActive} ref={paypalContentRef} $height={heights[1]}>
            <div>
              <ul>
                <ListItem>Fast, convenient payment option</ListItem>
                <ListItem>Keeps your financial info safe with end-to-end encryption</ListItem>
                <ListItem>Backed by PayPal’s industry-leading fraud protection</ListItem>
              </ul>
              <PaypalButton priceId={config.paddle.prices[tariffId]} email={email} />
              <ItemText>{secureText}</ItemText>
            </div>
          </ItemContent>
        </div>
      </PaymentFormBlock>

      {isProcessing && <Loader text="Processing..." isFixed={true} />}
    </>
  );
});

export default PaymentFormFields;
