import { useState, useEffect } from 'react';
import { useOutletContext, useNavigate, useSearchParams, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Container, Title, ButtonBottomFixedContainer } from 'components/ui';
import questionnaire from './questionnaire';
import { ROUTES_NAMES } from 'constants';
import funnelType from './FunnelType';
import ArrowLeft from 'assets/arrow-left';
import iconTickBlue from 'assets/icon-tick-blue.svg';

const ContainerStyled = styled(Container)`
  text-align: ${({ $isCenter }) => ($isCenter ? 'justify' : 'left')};
  white-space: pre-wrap;
`;
const TitlePositioned = styled(Title)`
  width: 100%;
  line-height: 1.5rem;
  margin-bottom: 24px;
  text-align: ${({ $isCenter }) => ($isCenter ? 'center' : 'left')};
`;

const Option = styled.label`
  display: block;
  width: 100%;
  padding: 10px 56px 10px 20px;
  border-radius: 12px;
  background-color: #fff;
  position: relative;
  font-size: 0.777rem;
  line-height: 1.166rem;
  color: #172335;
  cursor: pointer;
  font-weight: 600;
  user-select: none;
`;

const OptionsList = styled.div`
  width: 100%;
  margin-bottom: 32px;
  > label {
    margin-bottom: 10px;
  }
`;

const Radio = styled.input`
  position: absolute;
  right: 22px;
  top: 50%;
  opacity: 0;
  &:focus {
    outline: none;
  }
  & + span {
    display: block;
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #fff;
    border: 2px solid #ced1da;
    transition: border-color 0.2s;
  }
  &:checked + span {
    border: 5px solid #375dfb;
  }
`;

const Checkbox = styled.input`
  position: absolute;
  right: 22px;
  top: 50%;
  opacity: 0;
  &:focus {
    outline: none;
  }
  & + span {
    display: block;
    position: absolute;
    right: 22px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: 2px solid #ced1da;
    border-radius: 6px;
    transition: border-color 0.1s;
  }
  &:checked + span {
    border: 3px solid #375dfb;
    background-image: url(${iconTickBlue});
    background-position: center 0;
    background-repeat: no-repeat;
    background-size: 10px;
  }
`;

const ArrowStyled = styled.button`
  align-self: flex-start;
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 0;
  margin-bottom: 10px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

const Text = styled.p`
  font-size: 18px;
  line-height: 25px;
  font-weight: 500;
`;

const initialState = questionnaire.reduce((state, item) => {
  state[item.id] = [];
  return state;
}, {});

const Quiz = () => {
  const { incrementStep } = useOutletContext();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { id } = useParams(); // начинается с 1

  const [response, setResponse] = useState(initialState);
  const [currentQuestion, setCurrentQuestion] = useState(Number(id) - 1); // начинается с 0

  const item = questionnaire[currentQuestion];
  const isMultiple = item.type === funnelType.multipleSelect;

  useEffect(() => {
    setCurrentQuestion(Number(id) - 1);
  }, [id]);

  const onChange = (optionId) => {
    const optionData = item.options.find(({ id }) => id === optionId);
    if (isMultiple && !optionData.isSingle) {
      const isOtherSingle = item.options.find((item) => !!item.isSingle);
      let newAnswer = [];
      let prevAnswer = response[item.id];

      if (!!isOtherSingle) {
        prevAnswer = response[item.id].filter((item) => item !== isOtherSingle.id);
      }
      if (prevAnswer.includes(optionId)) {
        newAnswer = prevAnswer.filter((option) => option !== optionId);
      } else {
        newAnswer = [...prevAnswer, optionId];
      }

      setResponse((response) => ({
        ...response,
        [item.id]: newAnswer,
      }));
      return;
    }
    setResponse((response) => ({
      ...response,
      [item.id]: [optionId],
    }));
  };

  const handleClick = () => {
    if (currentQuestion === questionnaire.length - 1) {
      incrementStep();

      return;
      //save responses?
    }
    setTimeout(() => {
      navigate({
        pathname: `/${ROUTES_NAMES.QUIZ}/${Number(id) + 1}`,
        search: searchParams.toString(),
      });
    }, 300);
  };

  const handleQuizGoBack = () => {
    if (currentQuestion === 0) return;
    navigate(-1);
  };

  const Input = isMultiple ? Checkbox : Radio;

  const getContent = (contentType) => {
    console.log(contentType, currentQuestion);
    switch (contentType) {
      case funnelType.singleSelect:
      case funnelType.multipleSelect:
        console.log(currentQuestion);
        return (
          <OptionsList>
            {item.options.map((option) => (
              <Option key={option.id}>
                {option.text}
                <Input
                  type={isMultiple ? 'checkbox' : 'radio'}
                  name={item.id}
                  checked={response[item.id].includes(option.id)}
                  onChange={() => onChange(option.id)}
                  onClick={() => {
                    if (isMultiple) return;
                    handleClick();
                  }}
                />
                <span />
              </Option>
            ))}
          </OptionsList>
        );
      case funnelType.text:
        return <Text>{item.text}</Text>;
      default:
        return null;
    }
  };

  return (
    <ContainerStyled $isCenter={item.type === funnelType.text}>
      <ArrowStyled onClick={handleQuizGoBack} style={{ visibility: currentQuestion === 0 ? 'hidden' : 'visible' }}>
        <ArrowLeft />
      </ArrowStyled>
      <TitlePositioned $isCenter={item.type === funnelType.text}>{item.title}</TitlePositioned>
      {getContent(item.type)}

      {item.isButton && (
        <ButtonBottomFixedContainer>
          <Button onClick={handleClick} disabled={item.type !== funnelType.text && response[item.id].length === 0}>
            Continue
          </Button>
        </ButtonBottomFixedContainer>
      )}
    </ContainerStyled>
  );
};
export default Quiz;
