import { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

const AccordionItemBlock = styled.div`
  padding: 0 16px 16px;
  background-color: #fff;
  color: #172335;
  border-radius: 12px;
`;

const AccordionItemHeader = styled.div`
  padding: 26px 0 14px 46px;
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  background-image: ${({ $icon }) => `url(${$icon})`};
  background-position: 0px 19px;
  background-repeat: no-repeat;
  background-size: 40px;
  cursor: pointer;
  position: relative;
  &:hover {
    path {
      stroke: #61656f;
    }
  }
`;

const OpenButton = styled(motion.div)`
  position: absolute;
  top: 38px;
  right: 18px;
  width: 15px;
  height: 12px;
  transition: 0.2s;
  transform: ${({ $isOpened }) => ($isOpened ? 'rotate(180deg)' : 'rotate(0)')};
  transform-origin: center;
  line-height: 0;
`;

const AccordionItemContent = styled(motion.div)`
  height: 0;
  line-height: 0;
  overflow: hidden;
  & > div {
    font-size: 14px;
    line-height: 21px;
    padding-top: 12px;
  }
`;

const AccordionItemTitle = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
`;

const AccordionItemText = styled.div`
  color: #5d6572;
`;

const AccordionItem = ({ title, icon, content, children }) => {
  const [isOpened, setIsOpened] = useState(false);

  const onToggle = () => {
    setIsOpened((wasOpened) => !wasOpened);
  };

  return (
    <AccordionItemBlock>
      <AccordionItemHeader onClick={onToggle} $icon={icon}>
        {title}
        <OpenButton $isOpened={isOpened}>
          <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.999878 1.50005C0.999878 1.50005 5.41881 7.49999 6.99993 7.5C8.58104 7.50001 12.9999 1.5 12.9999 1.5"
              stroke="#9CA2AF"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </OpenButton>
      </AccordionItemHeader>

      <AccordionItemContent animate={{ height: isOpened ? 'auto' : 0 }}>
        <div>
          {content?.title && <AccordionItemTitle>{content.title}</AccordionItemTitle>}
          {content?.text && <AccordionItemText>{content.text}</AccordionItemText>}
          {children}
        </div>
      </AccordionItemContent>
    </AccordionItemBlock>
  );
};

export default AccordionItem;
