import App from './App';
import Error from './routes/error';
import EnterEmail from './routes/enterEmail';
import Progress from 'routes/progress';
import LeaksList from './routes/leaksList';
import ProtectData from './routes/protectData';
import ChoosePlan from './routes/choosePlan';
import PaymentPage from './routes/payment';
import Quiz from './routes/quiz';
import Fin from './routes/fin';
import GoToQuiz from './routes/goToQuiz';
import QuizResult from './routes/quizResult';
import PrivacyPolicy from './routes/policy/PrivacyPolicy';
import Terms from './routes/policy/Terms';
import { ROUTES_NAMES } from 'constants';

const routesConfig = [
  {
    path: '/',
    element: <App />,
    errorElement: <Error />,
    children: [
      { path: '/', index: true, element: <EnterEmail /> },
      { path: ROUTES_NAMES.PROGRESS, element: <Progress /> },
      { path: ROUTES_NAMES.LEAKS_LIST, element: <LeaksList /> },
      { path: ROUTES_NAMES.GO_TO_QUIZ, element: <GoToQuiz /> },
      { path: `${ROUTES_NAMES.QUIZ}/:id`, element: <Quiz /> },
      { path: ROUTES_NAMES.QUIZ_RESULT, element: <QuizResult /> },
      { path: ROUTES_NAMES.GO_TO_PAY, element: <ProtectData /> },
      { path: ROUTES_NAMES.CHOOSE_PLAN, element: <ChoosePlan /> },
      { path: ROUTES_NAMES.PAYMENT, element: <PaymentPage /> },
      { path: ROUTES_NAMES.SUCCESS, element: <Fin /> },
    ],
  },
  { path: ROUTES_NAMES.PRIVACY, element: <PrivacyPolicy /> },
  { path: ROUTES_NAMES.TERMS, element: <Terms /> },
];

export default routesConfig;
