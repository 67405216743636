import { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

const TimerStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  font-size: 18px;
  line-height: 21px;
  font-weight: 700;

  font-family: monospace;
`;

const TimerItem = styled.span`
  width: 45px;
  display: block;
  padding: 10px 10px 4px;
  border-radius: 6px;
  text-align: center;
  background-color: #fff;
`;

const TimerText = styled.span`
  display: block;
  font-size: 10px;
  line-height: 15px;
  font-weight: 500;
  color: #8f94a8;
`;

const getTime = (ms) => {
  let min = Math.floor((ms % (1000 * 60 * 60)) / (1000 * 60));
  let sec = Math.floor((ms % (1000 * 60)) / 1000);

  min = min < 10 ? `0${min}` : min;
  sec = sec < 10 ? `0${sec}` : sec;
  return {
    minutes: min,
    seconds: sec,
  };
};

const getStopTime = (delayMinutes) => {
  const startTime = new Date();
  const stopTime = startTime.setMinutes(startTime.getMinutes() + delayMinutes);
  return stopTime;
};

const Timer = ({ delayMinutes, theme }) => {
  const stopTime = useRef(getStopTime(delayMinutes));
  const [timeMs, setTime] = useState(delayMinutes * 1000 * 60);

  useEffect(() => {
    if (timeMs <= 0) {
      setTime(0);
      return;
    }
    const intervalId = setInterval(() => {
      if (timeMs <= 0) {
        clearInterval(intervalId);

        return;
      }
      setTime(stopTime.current - new Date().getTime());
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeMs]);

  const timeToRender = getTime(timeMs);

  if (theme === 'inline') {
    return (
      <span>
        {timeToRender.minutes}:{timeToRender.seconds}
      </span>
    );
  }

  return (
    <TimerStyled>
      <TimerItem>
        {timeToRender.minutes}
        <TimerText>min</TimerText>
      </TimerItem>
      <TimerItem>
        {timeToRender.seconds}
        <TimerText>sec</TimerText>
      </TimerItem>
    </TimerStyled>
  );
};

export default Timer;
