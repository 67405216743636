import Lottie from 'react-lottie';
import styled from 'styled-components';
import PercentProgress from 'components/percentProgress';
import background from 'assets/payment-guard-bg.svg';
import animatedGuard from './animatedGuard.json';

const HeadBlock = styled.div`
  margin-bottom: 26px;
`;

const ImageContainer = styled.div`
  position: relative;
  background-image: url(${background});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

const Counter = styled.span`
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  margin: auto;
  font-size: 41px;
  font-weight: 700;
  background-color: #ffffff;
  background-image: linear-gradient(to top, rgba(53, 131, 240, 0.6), transparent 50%);
  background-size: 100%;
  background-clip: text;
  color: transparent;
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
`;

const Subtitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  color: #babdc9;
  text-align: center;
`;

const HeadSection = () => {
  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animatedGuard,
    renderer: 'svg',
  };

  return (
    <HeadBlock>
      <ImageContainer>
        <Lottie options={defaultOptions} width={250} />
        <Counter>
          <PercentProgress initialValue={75} />
        </Counter>
      </ImageContainer>
      <Title>Protection Level</Title>
      <Subtitle>using Guard Pro</Subtitle>
    </HeadBlock>
  );
};
export default HeadSection;
