import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Wrapper from 'components/wrapper';
import { Button, Container, GuardBackground } from 'components/ui';

const ErrorBackground = styled(GuardBackground)`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorContainer = styled(Container)`
  height: auto;
  width: auto;
  align-self: center;
  text-align: center;
`;

const Error = () => {
  return (
    <Wrapper>
      <ErrorBackground>
        <ErrorContainer>
          Seems like there is a navigating error...
          <br />
          Please, go to the{' '}
          <Link to="/" style={{ margin: '20px auto' }}>
            <Button>Start screen</Button>
          </Link>
        </ErrorContainer>
      </ErrorBackground>
    </Wrapper>
  );
};

export default Error;
