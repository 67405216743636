import styled from 'styled-components';

export const PaymentContainer = styled.div`
  padding: 0 16px;
`;

export const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;

  color: #babdc9;
`;

export const AccentText = styled.span`
  color: #2f79e8;
`;

export const Section = styled.div`
  margin-bottom: 40px;
`;

export const Radio = styled.span`
  display: inline-block;
  width: ${({ $isSmall }) => ($isSmall ? '16px' : '20px')};
  height: ${({ $isSmall }) => ($isSmall ? '16px' : '20px')};

  border-width: ${({ $isActive, $isSmall }) => ($isActive ? ($isSmall ? '4px' : '5px') : '1px')};

  border-color: ${({ $isActive }) => ($isActive ? '#2f79e8' : '#81859c')};
  background-color: ${({ $isActive }) => ($isActive ? '#ffffff' : '#32364e')};
  border-radius: 50%;
  border-style: solid;
`;
