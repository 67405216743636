import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import Guard from 'assets/guard-main';
import { GuardBackground, Title, Text } from 'components/ui';
import { eventParams, sendPostBack } from 'api/analyticsHelper';
import { supportEmail, URL_PARAMS } from 'constants';

const GuardWrapper = styled.div`
  align-self: center;
`;

const SubTitle = styled(Title)`
  font-size: 1rem;
`;

const Fin = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    sendPostBack(eventParams.PAYMENT_SUCCESS, searchParams.get(URL_PARAMS.CLICK_ID));
  }, []);

  return (
    <GuardBackground style={{ gap: '20px', overflow: 'auto' }}>
      <div>
        <Title>Payment Successfully Processed</Title>
        <Text>Thank you for choosing our email data protection service!</Text>
      </div>
      <GuardWrapper>
        <Guard />
      </GuardWrapper>

      <div>
        <SubTitle>We'll notify you if someone tries to steal your data.</SubTitle>
        <Text>
          Our tools constantly monitor your account to detect any suspicious activity. If anything goes awry, we'll
          notify you immediately and take steps to protect your data. Your information's security is our top priority.
        </Text>
      </div>

      <Text
        style={{
          marginTop: 'auto',
        }}
      >
        For technical or product related questions&nbsp; email us at{' '}
        <a
          href={`mailto:${supportEmail}`}
          style={{
            color: '#465f93',
          }}
        >
          {supportEmail}
        </a>
      </Text>
    </GuardBackground>
  );
};
export default Fin;
