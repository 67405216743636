import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { Section } from '../../ui';
import slide1 from './assets/slide-1.svg';
import slide2 from './assets/slide-2.svg';
import slide3 from './assets/slide-3.svg';
import slide4 from './assets/slide-4.svg';
import 'swiper/css';
import 'swiper/css/pagination';

const SliderContainer = styled(Section)`
  position: relative;

  @media screen and (min-width: 500px) {
    &::before,
    &::after {
      content: '';
      width: 25px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 10;
    }
    &::before {
      left: 0;
      background-image: linear-gradient(to right, #010413 0%, transparent 100%);
    }
    &::after {
      right: 0;
      background-image: linear-gradient(to left, #010413 0%, transparent 100%);
    }
  }
  .swiper-wrapper {
    align-items: stretch;
  }
  .swiper-slide {
    width: 220px;
  }
  .swiper-pagination-bullet {
    background-color: #36383f;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background-color: #b8b9c2;
  }
  .swiper-pagination {
    bottom: -20px;
  }
  .pagination {
    line-height: 0;
    margin-top: 20px;
    text-align: center;
  }
`;

const Slide = styled.div`
  max-width: 220px;
  padding-bottom: 16px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  text-align: center;
  border-radius: 10px;
  background-color: #1a1c28;
  img {
    display: block;
    margin-bottom: 24px;
  }
  div {
    padding: 0 20px;
  }
`;
const Slider = () => {
  return (
    <SliderContainer>
      <Swiper
        slidesPerView="auto"
        spaceBetween={20}
        centeredSlides={true}
        pagination={{ clickable: true, el: '.pagination' }}
        loop={true}
        modules={[Pagination]}
      >
        <SwiperSlide>
          <Slide>
            <img src={slide1} alt="slide-1" />
            <div>Protect your data from&nbsp;hackers</div>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <img src={slide2} alt="slide-2" />
            <div>
              Financial Data
              <br />
              Protection
            </div>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <img src={slide3} alt="slide-3" />
            <div>Prevent theft of access to your account</div>
          </Slide>
        </SwiperSlide>
        <SwiperSlide>
          <Slide>
            <img src={slide4} alt="slide-4" />
            <div>Monitor for suspicious&nbsp;activity</div>
          </Slide>
        </SwiperSlide>
      </Swiper>
      <div className="pagination"></div>
    </SliderContainer>
  );
};

export default Slider;
