import styled from 'styled-components';
import { Container, TitleL, ButtonBottomPositioned } from 'components/ui';
import securityCheckIcon from 'assets/security-check.svg';
import userCheckIcon from 'assets/user-check.svg';
import webSucurityIcon from 'assets/web-security.svg';

const TitleLPositioned = styled(TitleL)`
  margin-bottom: 50px;
  text-align: center;
`;

const Item = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 23px;
  margin-bottom: 32px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemTitle = styled.div`
  font-size: 1.1rem;
  line-height: 1.7rem;
  font-weight: 700;
  color: #000;
  margin-bottom: 5px;
`;

const ItemText = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 500;
`;

const GoToQuizB = ({ incrementStep }) => {
  return (
    <Container>
      <TitleLPositioned>The perfect protection for you</TitleLPositioned>
      <Item>
        <img src={securityCheckIcon} alt="security-check" />
        <div>
          <ItemTitle>Security</ItemTitle>
          <ItemText>Protect your personal data and confidential information from hackers</ItemText>
        </div>
      </Item>
      <Item>
        <img src={userCheckIcon} alt="security-check" />
        <div>
          <ItemTitle>Peace of mind</ItemTitle>
          <ItemText>Get rid of the stress and worry of having your email hacked</ItemText>
        </div>
      </Item>
      <Item>
        <img src={webSucurityIcon} alt="security-check" />
        <div>
          <ItemTitle>Control</ItemTitle>
          <ItemText>Manage access to your email account and track suspicious activity</ItemText>
        </div>
      </Item>
      <ButtonBottomPositioned onClick={incrementStep}>Continue</ButtonBottomPositioned>
    </Container>
  );
};

export default GoToQuizB;
