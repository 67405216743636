import styled from 'styled-components';
import { useEffect, useRef, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Radar } from 'assets/radar';
import { Title, Text } from 'components/ui';
import { getLeaksData } from 'api/api';

const Container = styled.div`
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  padding: 24px;
`;

const Logo = styled.div`
  border-radius: 12px;
  background: #ebf4ff;
  padding: 12px;
  margin-bottom: 24px;
`;

const ProgressBar = styled.div`
  border: 1px solid #2f79e8;
  border-radius: 6px;
  width: 100%;
  margin: 8px 0;
  position: relative;
`;

const Indicator = styled.div`
  height: 6px;
  background: #2f79e8;
  border-radius: 1px;
  width: ${({ $w }) => ($w ? `${$w}%` : 0)};
  transition: width 0.5s;
  position: relative;
  z-index: 2;
`;

const Progress = () => {
  const { email, setLeaksData, incrementStep } = useOutletContext();
  const [progress, setProgress] = useState(0);
  const progressRef = useRef(0);
  const interval = useRef();
  const isFetched = useRef(false);

  useEffect(() => {
    if (!isFetched.current && email) {
      getLeaksData(email)
        .then((data) => {
          setLeaksData(data);
        })
        .catch((error) => {
          console.error('Driver updater error: ', error);
          setProgress(100);
          setTimeout(() => {
            incrementStep();
          }, 500);
          setLeaksData(null);
          clearInterval(interval.current);
        });
    }

    interval.current = setInterval(() => {
      if (progressRef.current >= 100) {
        setProgress(100);
        clearInterval(interval.current);
        return setTimeout(() => {
          incrementStep();
        }, 500);
      }
      progressRef.current = progressRef.current + 20;
      setProgress(progressRef.current);
    }, 1000);

    return () => {
      isFetched.current = true;
      clearInterval(interval.current);
    };
  }, []);

  return (
    <>
      <Container>
        <Logo>
          <Radar />
        </Logo>
        <Title>{progress >= 100 ? 'Scan Complete' : 'Scanning for Data Leaks...'}</Title>
        <ProgressBar>
          <Indicator $w={progress} />
        </ProgressBar>
        <Text style={{ textAlign: 'center' }}>
          Scan status for <code style={{ color: '#172335' }}>{email}</code>
        </Text>
      </Container>
    </>
  );
};

export default Progress;
