import styled from 'styled-components';
import { Text, Section } from '../../ui';
import guaranteePicture from 'assets/guarantee.svg';
import applePay from 'assets/paymentMethods/apple-pay.svg';
import googlePay from 'assets/paymentMethods/google-pay.svg';
import payPal from 'assets/paymentMethods/pay-pal.svg';
import americanExpress from 'assets/paymentMethods/american-express.svg';
import discover from 'assets/paymentMethods/discover.svg';
import masterCard from 'assets/paymentMethods/master-card.svg';
import visa from 'assets/paymentMethods/visa.svg';

const GuaranteeBlock = styled(Section)`
  padding: 16px;
  font-size: 14px;
  line-height: 21px;
  background-color: #1a1c28;
  border-radius: 16px;
  scroll-margin: 82px;
`;

const Picture = styled.img`
  display: block;
  width: 150px;
  margin: 0 auto 16px;
`;

const Title = styled.div`
  font-weight: 700;
  margin-bottom: 8px;
`;

const SubTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 12px;
`;

const TextGuarantee = styled(Text)`
  padding-bottom: 16px;
  margin-bottom: 16px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #404563;
  }
`;

const PaymentMethods = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 6px;
  img {
    width: 37px;
    height: 23px;
  }
`;

const Guarantee = () => {
  return (
    <GuaranteeBlock id="guarantee">
      <Picture src={guaranteePicture} />
      <Title>100% Money-Back Guarantee</Title>
      <TextGuarantee>
        We're so confident that Guard Pro will help you protect all your data that we'll refund your money if you don't
        see visible results within 4 weeks (and you can prove you followed our plan).
      </TextGuarantee>
      <SubTitle>Guaranteed Safe Checkout</SubTitle>
      <PaymentMethods>
        <img src={applePay} alt="applePay" />
        <img src={googlePay} alt="googlePay" />
        <img src={payPal} alt="payPal" />
        <img src={americanExpress} alt="americanExpress" />
        <img src={discover} alt="americanExpress" />
        <img src={masterCard} alt="masterCard" />
        <img src={visa} alt="visa" />
      </PaymentMethods>
    </GuaranteeBlock>
  );
};

export default Guarantee;
