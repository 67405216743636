import styled from 'styled-components';
import Timer from '../../../../../components/timer';
import discountBg from './discount-bg.svg';
import { sale } from '../../../../../priceData';

const PromoBlock = styled.div`
  padding: 12px 16px 12px 20px;
  display: flex;
  justify-content: space-between;
  gap: 32px;
  text-align: center;
  border-radius: 12px;
  background-color: #0dce89;
  color: #000;
  margin-bottom: 20px;
`;

const PromoTitle = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  margin-bottom: 8px;
`;

const PromoProtect = styled.div`
  padding: 25px 20px 11px 20px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 700;
  border-radius: 8px;
  position: relative;
  background-color: #fff;
`;

const PromoDiscount = styled.span`
  width: max-content;
  display: flex;
  gap: 5px;
  padding: 3px 16px 10px 8px;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-image: url(${discountBg});
  background-position: 0 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #ffffff;
`;

const PromoOld = styled.span`
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  text-decoration: line-through;
  text-decoration-color: #ea3f3f;
`;

const PromoCurrent = styled.span`
  flex-shrink: 0;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
`;

const Promo = () => {
  return (
    <PromoBlock>
      <div>
        <PromoTitle>Applied limited discount</PromoTitle>
        <PromoProtect>
          <PromoDiscount>
            <PromoOld>{sale.old}</PromoOld>
            <PromoCurrent>{sale.current} off</PromoCurrent>
          </PromoDiscount>
          Full Protection
        </PromoProtect>
      </div>
      <div>
        <PromoTitle>Applicable for</PromoTitle>
        <Timer delayMinutes={10} theme="block" />
      </div>
    </PromoBlock>
  );
};

export default Promo;
