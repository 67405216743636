import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ExpressCheckoutElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { createCustomerAndSubscription } from 'api/api';
import { URL_PARAMS, ROUTES_NAMES } from 'constants';

const PaymentMethodButtons = ({ priceId, email, incrementStep }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState();
  const [visibility, setVisibility] = useState('hidden');

  const [searchParams] = useSearchParams();

  const onReady = ({ availablePaymentMethods }) => {
    if (availablePaymentMethods) {
      setVisibility('visible');
    }
  };

  const onConfirm = async (event) => {
    if (!stripe) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message);
      return;
    }

    // Create a PaymentMethod using the details collected by the Express Checkout Element
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      elements,
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      setErrorMessage(error.message);
    }

    const { type, clientSecret } = await createCustomerAndSubscription(
      email,
      priceId,
      !!searchParams.get(URL_PARAMS.TEST),
    );

    const confirmIntent = type === 'setup' ? stripe.confirmCardSetup : stripe.confirmCardPayment;

    const { error: subError /* setupIntent */ } = await confirmIntent(clientSecret, {
      payment_method: paymentMethod.id,
    });

    if (subError) {
      // This point is only reached if there's an immediate error when confirming the Intent.
      // Show the error to your customer (for example, "payment details incomplete").
      // handleError(error);
      setErrorMessage(subError.message);
    } else {
      incrementStep(ROUTES_NAMES.SUCCESS);
    }
  };

  const isPaymentsMethodsVisible = visibility === 'visible';
  return (
    <>
      <div
        style={{
          visibility,
          height: isPaymentsMethodsVisible ? 'auto' : 0,
          padding: isPaymentsMethodsVisible ? '16px 0 10px' : 0,
          overflow: 'hidden',
        }}
      >
        <ExpressCheckoutElement
          onReady={onReady}
          onConfirm={onConfirm}
          options={{
            layout: {
              maxColumns: 2,
              maxRows: 1,
            },
            buttonType: {
              googlePay: 'plain',
            },
            paymentMethodOrder: ['applePay', 'googlePay'],
            wallets: {
              applePay: 'always',
              googlePay: 'auto',
            },
          }}
        />
      </div>

      {errorMessage && (
        <p
          style={{
            marginTop: '8px',
            minHeight: '1rem',
          }}
        >
          {errorMessage}
        </p>
      )}
    </>
  );
};

export default PaymentMethodButtons;
