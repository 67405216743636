import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import styled from 'styled-components';
import { getGoogleUserInfo } from 'api/api';

const GoogleButton = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 16px 22px;
  background-color: #fff;
  border: 1px solid #dadce0;
  border-radius: 16px;
  color: #172335;
  cursor: pointer;
  margin-bottom: 21px;
`;

const TextNoteWrapper = styled.div`
  width: 100%;
  padding: 12px 8px 11px 8px;
  text-align: center;
  position: relative;
  line-height: 21px;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    top: calc(50% - 1px);
    left: 0;
    opacity: 0.5;
    background-color: #e5e6ef;
  }
`;

const TextNote = styled.span`
  display: inline-block;
  padding: 0 10px;
  font-size: 0.87rem;
  text-transform: uppercase;
  color: rgba(21, 51, 53, 0.5);
  background-color: #fff;
  position: relative;
  z-index: 2;
`;

const clientId = '42388856676-oo2gfibqpladtgejns5akd0fk7lumpbf.apps.googleusercontent.com'; // need to set from google project!

const GoogleLoginButton = ({ onSuccess }) => {
  const Login = useGoogleLogin({
    onError: (response) => {
      console.error('Google login error: ', response);
    },
    callback: async (data) => {
      const userInfo = await getGoogleUserInfo(data);

      if (!userInfo?.email) {
        console.error('Google login callback error: ', data);
        return;
      }
      onSuccess(userInfo.email);
    },
  });
  return (
    <GoogleButton
      onClick={() => {
        Login();
      }}
    >
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30" height="30">
        <g>
          <path
            fill="#EA4335"
            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
          ></path>
          <path
            fill="#4285F4"
            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
          ></path>
          <path
            fill="#FBBC05"
            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
          ></path>
          <path
            fill="#34A853"
            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
          ></path>
          <path fill="none" d="M0 0h48v48H0z"></path>
        </g>
      </svg>{' '}
      Continue with Google
    </GoogleButton>
  );
};

const GoogleLoginBlock = ({ onSuccess, onError }) => {
  if (!clientId) return null;
  return (
    <>
      <TextNoteWrapper>
        <TextNote>or</TextNote>
      </TextNoteWrapper>
      <GoogleOAuthProvider clientId={clientId}>
        <GoogleLoginButton onSuccess={onSuccess} onError={onError} />
      </GoogleOAuthProvider>
    </>
  );
};

export default GoogleLoginBlock;
