export const baseUrl = 'https://stripe-gateway-15c66b25fd2e.herokuapp.com';
export const supportEmail = 'info@guardproapp.com';

export const DEFAULT_USER_COUNT = 129788;

export const URL_PARAMS = {
  CLICK_ID: 'clickid',
  TEST: 'test',
  START_QUIZ_SCREEN: 'startQuizScreen',
  PAYMENT_IN_SEPARATE_SCREEN: 'paymentInSeparateScreen',
  COUTNRY_CODE: 'country_code',
};

export const ROUTES_NAMES = {
  ENTER_EMAIL: '',
  PROGRESS: 'progress',
  LEAKS_LIST: 'leaks-list',
  GO_TO_QUIZ: 'start-quiz',
  QUIZ: 'quiz',
  QUIZ_RESULT: 'quiz-result',
  GO_TO_PAY: 'privacy-protect',
  CHOOSE_PLAN: 'choose-plan',
  PAYMENT: 'payment',
  SUCCESS: 'success',

  PRIVACY: 'privacy',
  TERMS: 'terms',
};

export const routesInOrder = [
  ROUTES_NAMES.ENTER_EMAIL,
  ROUTES_NAMES.PROGRESS,
  ROUTES_NAMES.LEAKS_LIST,
  ROUTES_NAMES.GO_TO_QUIZ,
  ROUTES_NAMES.QUIZ,
  ROUTES_NAMES.QUIZ_RESULT,
  ROUTES_NAMES.GO_TO_PAY,
  ROUTES_NAMES.CHOOSE_PLAN,
  ROUTES_NAMES.PAYMENT,
  ROUTES_NAMES.SUCCESS,
];
