import { useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import Lottie from 'react-lottie';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';
import { Container, Title } from 'components/ui';
import PercentProgress from 'components/percentProgress';
import LineProgress from 'components/lineProgress';
import ReviewItem from 'components/reviewItem';
import reviewData from 'reviewData';
import planProgess from './planProgress.json';
import planReady from './planReady.json';
import { DEFAULT_USER_COUNT } from 'constants';

const ContainerBg = styled(Container)`
  justify-content: center;
`;

const PictureWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  height: 250px;
  width: 242px;
  margin: 0 auto;
`;

const Picture = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  max-height: 240px;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 36px;
  text-align: center;
`;

const TitlePositioned = styled(Title)`
  margin-bottom: 12px;
`;

const LineProgressWrapper = styled.div`
  margin-bottom: 12px;
`;

const Subscription = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const Slider = styled.div`
  width: 100%;
  width: calc(100% + 48px);
  margin-left: -24px;
  margin-bottom: 24px;
  position: relative;
  .swiper-slide {
    width: 280px;
  }
  @media screen and (min-width: 500px) {
    &::before,
    &::after {
      content: '';
      width: 20px;
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 10;
    }
    &::before {
      left: 0;
      background-image: linear-gradient(to right, #e9ebff 0%, transparent 100%);
    }
    &::after {
      right: 0;
      background-image: linear-gradient(to left, #e9ebff 0%, transparent 100%);
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const AccentText = styled.span`
  color: #0a0d14;
  font-weight: 600;
`;

const QuizResult = () => {
  const { incrementStep } = useOutletContext();
  const [isReady, setIsReady] = useState(false);

  const userCount = (+localStorage.getItem('userCount') || DEFAULT_USER_COUNT).toLocaleString('en-US');

  const defaultOptions = {
    loop: false,
    autoplay: true,
    renderer: 'svg',
  };

  const updateAnimation = () => {
    setIsReady(true);
    setTimeout(() => {
      incrementStep();
    }, 1000);
  };

  return (
    <ContainerBg>
      <PictureWrapper>
        <Picture
          animate={{
            opacity: isReady ? 0 : 1,
          }}
        >
          <Lottie options={{ ...defaultOptions, animationData: planProgess }} width={242} height={240} />
        </Picture>
        <Picture
          animate={{
            opacity: isReady ? 1 : 0,
          }}
        >
          <Lottie options={{ ...defaultOptions, animationData: planReady }} width={242} />
        </Picture>
      </PictureWrapper>

      <Header>
        <TitlePositioned>Analyzing your answers</TitlePositioned>
        <LineProgressWrapper>
          <LineProgress duration={4} onAnimationComplete={updateAnimation} />
        </LineProgressWrapper>

        <Subscription>
          Preparing advanced security solutions
          <PercentProgress duration={4} />
        </Subscription>
      </Header>
      <Slider>
        <Swiper
          slidesPerView="auto"
          spaceBetween={12}
          loop={true}
          centeredSlides={true}
          speed={3000}
          autoplay={{
            delay: 2000,
            disableOnInteraction: true,
          }}
          modules={[Autoplay]}
        >
          {reviewData.map((item, i) => {
            return (
              <SwiperSlide>
                <ReviewItem
                  key={i}
                  theme="light"
                  title={item.title}
                  text={item.text}
                  name={item.name}
                  rating={item.rating}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      </Slider>
      <Footer>
        <AccentText>{userCount}</AccentText> users protected by Guard Pro
      </Footer>
    </ContainerBg>
  );
};

export default QuizResult;
