import styled from 'styled-components';
import { supportEmail } from 'constants';

const Text = styled.div`
  max-width: 250px;

  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  margin: 0 auto 16px;
  text-align: center;
  color: #babdc9;
  &:last-child {
    margin-bottom: 0;
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div>
      <Text>
        For technical or product related questions email us at <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </Text>
      <Text>{year} © All Rights Reserved</Text>
    </div>
  );
};

export default Footer;
