import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import backgroundLight from 'assets/bacground-light.svg';
import Timer from 'components/timer';

const HeightSaver = styled.div`
  height: 66px;
  margin-bottom: 24px;
`;

const SellerBlock = styled.div`
  position: ${({ $isFixed }) => ($isFixed ? 'fixed' : 'static')};
  top: 0;
  left: 50%;
  transform: ${({ $isFixed }) => ($isFixed ? 'translateX(-50%)' : 'translateX(0)')};
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 10px 12px 20px;
  border-radius: ${({ $isFixed }) => ($isFixed ? '0' : '8px')};
  border-bottom: ${({ $isFixed }) => ($isFixed ? '1px solid #474C66' : 'none')};
  filter: ${({ $isFixed }) => ($isFixed ? 'drop-shadow(0 4px 13px  #000)' : 'none')};
  background-color: #1e202b;
  background-image: url(${backgroundLight});
  background-repeat: no-repeat;
  background-size: 67px;
  background-position: 144px 5px;
  z-index: 11;
`;

const SellerTitle = styled.div`
  font-size: 12px;
  line-height: 14px;
  margin-bottom: 4px;
`;

const SellerTimer = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const Button = styled.a`
  padding: 14px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 10px;
  background-color: #2f79e8;
  color: #ffffff;
  text-decoration: none;
  user-select: none;
`;

const Seller = () => {
  const [isFixed, setIsFixed] = useState(false);
  const blockRef = useRef(null);
  const formEl = document.querySelector('#form');

  const setBlockPosition = () => {
    if (!blockRef.current) return;
    const scrollTop = formEl.scrollTop;
    if (scrollTop >= blockRef.current - 66) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  useEffect(() => {
    if (blockRef.current && typeof blockRef.current !== 'number') {
      blockRef.current = blockRef.current?.getBoundingClientRect().top;
    }
    if (formEl) {
      formEl.addEventListener('scroll', setBlockPosition);
    }
    return () => {
      if (formEl) {
        formEl.removeEventListener('scroll', setBlockPosition);
      }
    };
  }, [formEl]);

  const scrollToTariffs = () => {
    document.body.querySelector('#tariff').scrollIntoView();
  };

  return (
    <HeightSaver>
      <SellerBlock ref={blockRef} $isFixed={isFixed}>
        <div>
          <SellerTitle>Discount reserved for</SellerTitle>
          <SellerTimer>
            <Timer delayMinutes={10} theme="inline" />
          </SellerTimer>
        </div>
        <Button onClick={scrollToTariffs}>Get My Plan</Button>
      </SellerBlock>
    </HeightSaver>
  );
};
export default Seller;
