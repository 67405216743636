const noLeaksData = [
  {
    Name: 'Facebook',
    Title: 'Facebook',
    Domain: 'facebook.com',
    BreachDate: '2019-08-01',
    AddedDate: '2021-04-04T03:20:45Z',
    ModifiedDate: '2021-04-06T09:09:21Z',
    PwnCount: 509458528,
    Description:
      "In April 2021, a large data set of over 500 million Facebook users was made freely available for download. Encompassing approximately 20% of Facebook's subscribers, the data was allegedly obtained by exploiting a vulnerability Facebook advises they rectified in August 2019. The primary value of the data is the association of phone numbers to identities; whilst each record included phone, only 2.5 million contained an email address. Most records contained names and genders with many also including dates of birth, location, relationship status and employer.",
    LogoPath: 'https://haveibeenpwned.com/Content/Images/PwnedLogos/Facebook.png',
    DataClasses: [
      'Dates of birth',
      'Email addresses',
      'Employers',
      'Genders',
      'Geographic locations',
      'Names',
      'Phone numbers',
      'Relationship statuses',
    ],
    IsVerified: true,
    IsFabricated: false,
    IsSensitive: false,
    IsRetired: false,
    IsSpamList: false,
    IsMalware: false,
    IsSubscriptionFree: false,
  },
  {
    Name: 'Yahoo',
    Title: 'Yahoo',
    Domain: 'yahoo.com',
    BreachDate: '2012-07-11',
    AddedDate: '2013-12-04T00:00:00Z',
    ModifiedDate: '2013-12-04T00:00:00Z',
    PwnCount: 453427,
    Description:
      'In July 2012, Yahoo! had their online publishing service &quot;Voices&quot; compromised via a SQL injection attack. The breach resulted in the disclosure of nearly half a million usernames and passwords stored in plain text. The breach showed that of the compromised accounts, a staggering 59% of people who also had accounts in the Sony breach reused their passwords across both services.',
    LogoPath: 'https://haveibeenpwned.com/Content/Images/PwnedLogos/Yahoo.png',
    DataClasses: ['Email addresses', 'Passwords'],
    IsVerified: true,
    IsFabricated: false,
    IsSensitive: false,
    IsRetired: false,
    IsSpamList: false,
    IsMalware: false,
    IsSubscriptionFree: false,
  },
  {
    Name: 'LinkedInScrape',
    Title: 'LinkedIn Scraped Data (2021)',
    Domain: 'linkedin.com',
    BreachDate: '2021-04-08',
    AddedDate: '2021-10-02T21:39:21Z',
    ModifiedDate: '2023-11-07T06:51:33Z',
    PwnCount: 125698496,
    Description:
      'During the first half of 2021, LinkedIn was targeted by attackers who scraped data from hundreds of millions of public profiles and later sold them online. Whilst the scraping did not constitute a data breach nor did it access any personal data not intended to be publicly accessible, the data was still monetised and later broadly circulated in hacking circles. The scraped data contains approximately 400M records with 125M unique email addresses, as well as names, geographic locations, genders and job titles. LinkedIn specifically addresses the incident in their post on An update on report of scraped data.',
    LogoPath: 'https://haveibeenpwned.com/Content/Images/PwnedLogos/LinkedIn.png',
    DataClasses: [
      'Education levels',
      'Email addresses',
      'Genders',
      'Geographic locations',
      'Job titles',
      'Names',
      'Social media profiles',
    ],
    IsVerified: true,
    IsFabricated: false,
    IsSensitive: false,
    IsRetired: false,
    IsSpamList: false,
    IsMalware: false,
    IsSubscriptionFree: false,
  },
];

export default noLeaksData;
