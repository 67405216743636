import styled from 'styled-components';
import { Section } from '../../ui';
import { ButtonPayment } from 'components/ui';
import likePicture from './assets/like.svg';
import palmLeft from './assets/palm-left.svg';
import palmRight from './assets/palm-right.svg';
import starShape from 'assets/icon-star-shape.svg';
import star from 'assets/icon-star-full.svg';
import starHalf from 'assets/icon-star-half.svg';

const Like = styled.div`
  display: flex;
  gap: 20px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  margin-bottom: 32px;
`;

const Block = styled.div`
  padding: 20px;
  border-radius: 12px;
  background-color: #1a1c28;
  text-align: center;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
`;

const Gray = styled.span`
  color: #babdc9;
`;

const Header = styled.div`
  padding: 4px 0;
  background-image: url(${palmLeft}), url(${palmRight});
  background-position:
    top left,
    top right;
  background-repeat: no-repeat;
  margin-bottom: 35px;
`;

const HeaderTitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 6px;
`;

const HeaderText = styled(Text)`
  max-width: 175px;
  margin: 0 auto;
  color: #babdc9;
`;

const Rate = styled.div`
  max-width: 145px;
  margin: 0 auto;
  margin-bottom: 33px;
`;

const RateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 6px;
  span {
    display: flex;
    align-items: center;
    gap: 6px;
  }
`;

const RateContent = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 3px;
  img {
    width: 26px;
    height: 26px;
  }
`;

const Content = styled(Text)`
  max-width: 200px;
  margin: 0 auto;
  margin-bottom: 20px;
`;

const Grateful = ({ onButtonClick }) => {
  return (
    <Section>
      <Like>
        <img src={likePicture} alt="" />
        <p>People using Guard Pro for 3 months completely protect their data and passwords.</p>
      </Like>
      <Block>
        <Header>
          <HeaderTitle>1M+ Grateful Users</HeaderTitle>
          <HeaderText>Protect your data with Guard Pro</HeaderText>
        </Header>
        <Rate>
          <RateHeader>
            <span>
              <img src={starShape} alt="" />
              Trustpilot
            </span>
            <Gray>Excellent</Gray>
          </RateHeader>
          <RateContent>
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={star} alt="" />
            <img src={starHalf} alt="" />
          </RateContent>
        </Rate>
        <Content>Over 250,000 5 star ratings from happy users 😍</Content>
        <ButtonPayment onClick={onButtonClick}>Get My Plan</ButtonPayment>
      </Block>
    </Section>
  );
};

export default Grateful;
