import { useState, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentInfoText from 'components/paymentInfoText';
import Loader from 'components/loader';
import PaymentFormFields from './paymentFormFields';
import { Subtitle } from 'components/ui';
import { ConfigsContext } from 'App';

const PaymentFormContainer = styled.div`
  scroll-margin: 80px;
`;

const PaymentForm = ({ email, currentTariff, incrementStep }) => {
  const [stripePromise, setStripePromise] = useState(null);
  const { config } = useContext(ConfigsContext);

  useEffect(() => {
    if (!config) return;
    setStripePromise(loadStripe(config.stripe.publishableKey));
  }, [config]);

  const options = {
    mode: 'subscription',
    amount: 100,
    currency: 'usd',
    paymentMethodCreation: 'manual',
  };

  return (
    <PaymentFormContainer id="payment-form">
      <Subtitle className="form-title">Payment method</Subtitle>
      {!stripePromise && <Loader isFixed={true} />}
      {config && (
        <Elements stripe={stripePromise} options={options} key={1}>
          <PaymentFormFields incrementStep={incrementStep} email={email} tariffId={currentTariff.id} />
        </Elements>
      )}
      <PaymentInfoText fullPrice={currentTariff.fullPrice} />
    </PaymentFormContainer>
  );
};

export default PaymentForm;
