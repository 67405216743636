import styled from 'styled-components';

const RotateContainer = styled.div`
  position: relative;
  width: 40px;
  height: 40px;

  & svg {
    animation: rotate 1s linear 0s infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
  @keyframes rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  position: ${({ $isFixed }) => ($isFixed ? 'fixed' : 'static')};

  background: ${({ $isFixed }) => $isFixed && 'rgba(0, 0, 0, 0.7)'};
  padding: ${({ $isFixed }) => $isFixed && '24px'};
  z-index: ${({ $isFixed }) => $isFixed && '105'};
`;

const Rotate = () => (
  <RotateContainer>
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
          d="M20 10C20 11.9778 19.4135 13.9112 18.3147 15.5557C17.2159 17.2002 15.6541 18.4819 13.8268 19.2388C11.9996 19.9957 9.98891 20.1937 8.0491 19.8079C6.10929 19.422 4.32746 18.4696 2.92893 17.0711C1.53041 15.6725 0.577999 13.8907 0.192147 11.9509C-0.193705 10.0111 0.00432836 8.00043 0.761205 6.17317C1.51808 4.3459 2.79981 2.78412 4.4443 1.6853C6.08879 0.58649 8.02219 -2.35852e-08 10 0L10 1.34444C8.28809 1.34444 6.61463 1.85208 5.19123 2.80317C3.76783 3.75425 2.65843 5.10606 2.00331 6.68766C1.34819 8.26926 1.17678 10.0096 1.51076 11.6886C1.84473 13.3676 2.66909 14.9099 3.8796 16.1204C5.0901 17.3309 6.63237 18.1553 8.31138 18.4892C9.9904 18.8232 11.7307 18.6518 13.3123 17.9967C14.8939 17.3416 16.2457 16.2322 17.1968 14.8088C18.1479 13.3854 18.6556 11.7119 18.6556 10H20Z"
          fill="#2F80ED"
        />
      </svg>
    </div>
  </RotateContainer>
);

export const Loader = ({ width = '', text = '', isFixed = false }) => {
  return (
    <LoaderContainer $isFixed={isFixed}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '12px',
          width,
        }}
      >
        <Rotate />
        {text && <p style={{ color: '#fff' }}>Processing...</p>}
      </div>
    </LoaderContainer>
  );
};

export default Loader;
