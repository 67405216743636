import { Section } from '../../ui';
import ReviewItem from 'components/reviewItem';
import reviewData from 'reviewData';

const Review = () => {
  return (
    <Section>
      {reviewData.map((item, i) => {
        return (
          <ReviewItem
            key={`${i}-${item.name}`}
            rating={item.rating}
            title={item.title}
            text={item.text}
            name={item.name}
          />
        );
      })}
    </Section>
  );
};

export default Review;
