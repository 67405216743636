import styled from 'styled-components';
import { Radio } from '../../routes/choosePlan/ui';
import arrow from 'assets/icon-arrow-right.svg';

const TariffCol = styled.div`
  display: flex;
  align-items: center;
`;
const TariffColPrice = styled.div`
  display: flex;
  align-items: flex-end;
  color: #8f96b7;
`;

const TariffTitle = styled.span`
  display: block;
  font-size: 14px;
  line-height: 17px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
`;

const TariffSubtitle = styled.span`
  display: block;
  font-size: 10px;
  line-height: 12px;
  font-weight: 600;
  color: #8f96b7;
`;

const OldPrice = styled.span`
  text-decoration: line-through;
  font-weight: 600;
  text-decoration-color: #ea3f3f;
`;

const TariffTitlePriceOld = styled(OldPrice)`
  padding-right: 20px;
  background-image: url(${arrow});
  background-position: right 8px top 3px;
  background-repeat: no-repeat;
  background-size: 8px 6px;
`;

const RadioPositioned = styled(Radio)`
  margin-right: 20px;
`;

const TariffPrice = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  padding: 5px 10px 5px 20px;
  position: relative;
  z-index: 1;
  font-size: 12px;
  svg {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
`;

const CurrentPrice = styled.span`
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
`;

const PriceText = styled.span`
  font-size: 10px;
  line-height: 12px;
  font-weight: 500;
`;

const Popular = styled.span`
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  padding: 2px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color: #40507a;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  text-align: center;
  color: #fff;
`;

const Tariff = styled.div`
  width: 100%;
  padding: 6px 8px 6px 18px;
  padding-top: ${({ $isPopular }) => ($isPopular ? '24px' : '10px')};
  display: flex;
  justify-content: space-between;
  background-color: #242738;
  border-radius: 8px;
  border-width: 2px;
  border-style: solid;
  border-color: ${({ $isActive }) => ($isActive ? '#2f79e8' : '#242738')};
  margin-bottom: 8px;
  position: relative;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  ${RadioPositioned} {
    border: ${({ $isActive }) => $isActive && '5px solid #2f79e8'};
    background-color: ${({ $isActive }) => $isActive && '#ffffff'};
  }
  ${TariffColPrice} {
    color: ${({ $isActive }) => $isActive && '#ffffff'};
  }
  ${Popular} {
    background-color: ${({ $isActive }) => $isActive && '#2f79e8'};
  }
  svg path {
    fill: ${({ $isActive }) => $isActive && '#192771'};
  }
`;

const TariffItem = ({ tariff, currentTariff, onChange, isRadio = true }) => {
  return (
    <Tariff
      $isPopular={tariff.isPopular}
      onClick={() => onChange(tariff)}
      $isActive={currentTariff.title === tariff.title}
    >
      {tariff.isPopular && <Popular>MOST POPULAR</Popular>}
      <TariffCol>
        {isRadio && <RadioPositioned $isActive={currentTariff.title === tariff.title} />}

        <TariffTitle>
          {tariff.title} plan
          <TariffSubtitle>
            <TariffTitlePriceOld>{tariff.oldPrice}</TariffTitlePriceOld>
            {tariff.currentPrice}
          </TariffSubtitle>
        </TariffTitle>
      </TariffCol>
      <TariffColPrice>
        <TariffPrice>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="86"
            height="64"
            preserveAspectRatio="none"
            viewBox="0 0 86 64"
            fill="none"
          >
            <path
              d="M82 4.45724e-08C84.2091 1.99558e-08 86 1.79086 86 4L86 60C86 62.2091 84.2091 64 82 64L14.3508 64C12.6649 64 11.16 62.943 10.588 61.3571L1.46835 36.0713C0.51937 33.44 0.51937 30.56 1.46835 27.9287L10.588 2.64291C11.16 1.05702 12.6649 8.17181e-07 14.3508 7.98395e-07L82 4.45724e-08Z"
              fill="#383D57"
            />
          </svg>
          <OldPrice>{tariff.perDay.old}</OldPrice>
          <CurrentPrice>{tariff.perDay.current}</CurrentPrice>
          <PriceText>per day</PriceText>
        </TariffPrice>
      </TariffColPrice>
    </Tariff>
  );
};
export default TariffItem;
