const rating = new Array(5).fill('');
const reviewData = [
  {
    rating: rating,
    name: 'Nikole M.',
    title: 'Love this protection!',
    text: "Guard Pro is a game-changer! Since installing it, I feel much more confident about the security of my personal data. It's like having a virtual bodyguard for my phone",
  },
  {
    rating: rating,
    name: 'Jane M.',
    title: 'Love this protection!',
    text: 'Guard Pro is a lifesaver! I feel much more secure with it on my phone',
  },
  {
    rating: rating,
    name: 'Mark',
    title: 'All data is safe',
    text: "I've tried several security apps, but Guard Pro stands out. It's user-friendly, and I appreciate how it constantly updates to stay ahead of potential threats. Highly recommended",
  },
  {
    rating: rating,
    name: 'Nikole A.',
    title: 'Love this protection!',
    text: 'Works great! All my data is protected.',
  },
];
export default reviewData;
