import { supportEmail } from 'constants';
import './styles.css';

const Policy = () => {
  return (
    <div class="content">
      <h1 class="title">Privacy Policy - "GuardPRO"</h1>
      <p class="sub-title">Last modified January 2024</p>
      <br />
      <br />
      <div class="text-body">
        <p>
          This privacy policy (the <b>"Privacy Policy"</b>) is made as an integral part of the Terms Of Use (“
          <b>Terms of Use</b>”) and is governing the collection, use, processing, and sharing of information related to
          you pertaining to your access and/or use of the GuardPRO Services (as such defined in the Terms of Use) or any
          part thereof, including without limitation the GuardPRO website available at{' '}
          <a href="https://guardproapp.com/">guardproapp.com</a> (the Terms of Use together with this Privacy Policy the
          “<b>Terms</b>”, and both documents should be read together). All capitalized terms which are not otherwise
          defined in this privacy policy shall have the meaning attributed to them in the Terms of Use. This policy may
          use the terms Services interchangeably and wherever it refers to the Services, it shall be deemed to mean all
          of the Services offered by GuardPRO from time to time under the Terms.
        </p>
        <p>
          whenever you’ll provide GuardPRO with such information, GuardPRO will only use your information in line with
          all applicable data protection laws. At all times You are in control of Your information, and You can instruct
          us to cease the collection and/or processing of your Personal Information pursuant to your rights under
          applicable law.
        </p>
        <p>
          To the extent applicable, GuardPRO operates as the data controller of the Personal Information provided by you
          in connection with the access and/or use of the GuardPRO Services.
        </p>
        <h2 class="paragraph-sub-title">1. General</h2>
        <p>
          For the purposes of this Privacy Policy “Personal Information” shall mean any information related to an
          identified or identifiable natural person.
          <br />
          Specifically, during Your access and/or use of the Services, we will collect or receive the following
          information (including Personal Information) about You:
        </p>
        <ul>
          <li>
            Anonymized browsing behavior needed inter alia for the operation of the Solution including sites and URLs
            visited during the Solution’s operation.
          </li>
          <li>
            Country, IP address, Installation time, E-mail, name, last name (as provided by the user), 4 digits of
            credit card, credit card type for paying customers, and other information provided by you during and as part
            of creating and maintaining an account with us
          </li>
          <li>
            To the extent you have chosen to subscribe to and use our email scanning feature and/or SMS messages
            scanning feature as part of the Services, we will also receive information as follows: (a) when you use our
            email scanning feature – the information (including email content) contained in your email inbox as of your
            subscription to the service and information contained in any email you receive thereafter during your use of
            the Services; and (b) when you use our SMS messages scanning feature, information contained in the SMS
            messages (including messages content) you receive as of the subscription to such feature and thereafter
            during your use thereof, all as described in your subscription and as made available by us.
          </li>
        </ul>
      </div>
      <p>
        <b>
          Please note that where you provide us with Personal Information pertaining to third parties you hereby
          acknowledge that and agree that you have received and obtained all necessary approvals, authorization, and
          consents required to do so under any applicable law.
        </b>
      </p>
      <h2 class="paragraph-sub-title">2. Your Rights Regarding Your Personal Information</h2>
      <p>
        2.1 Under the applicable laws and/or regulations you might and subject to different exemptions, have the
        following rights:
      </p>
      <ul>
        <li>
          To access Your Personal Information (as such term is defined in the applicable regulation) and receive a copy
          of Your Personal Information upon Your written request;
        </li>
        <li>To rectify, erase or restrict Your Personal Information upon Your request;</li>
        <li>To object to the processing of Your Personal Information;</li>
        <li>
          To request that GuardPRO will provide You with Your Personal Information and, if possible, transmit Your
          Personal Information directly to another data controller.
        </li>
        <li>
          To instruct us to cease, and as applicable withdraw your consent to, processing of your Personal Information
          at any time;
        </li>
      </ul>
      <p>
        2.2. No fees are required for exercising any of the above rights. However and subject to the applicable law and
        regulations, We may charge You a reasonable fee including administration fees if Your request is repetitive or
        excessive or demands Us to bear excessive and/or expensive efforts
      </p>
      <p>
        To exercise your rights, please contact GuardPRO’s Data Protection Officer at the following contact details:{' '}
        <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
      </p>
      <h2 class="paragraph-sub-title">3. Database and Use of Information</h2>
      <p>
        3.1. The collected information as stated above is stored in GuardPRO’s database and shall be used and processed
        by us only for the following purposes:
      </p>
      <ul>
        <li>
          Providing the Services or any part thereof and enabling convenient and efficient use of thereof including, as
          applicable, third-party services made available via our Services;
        </li>
        <li>Improve and enrich the Services;</li>
        <li>Modify and/or remove existing Services and content;</li>
        <li>
          Perform research and provide statistical information to third parties (in such case, the provided information
          will not identify You);
        </li>
        <li>Enforce the GuardPRO’s Terms;</li>
        <li>Collecting of payable fees;</li>
        <li>Providing additional services and/or products;</li>
        <li>Any other purpose detailed in the Terms and this Privacy Policy.</li>
      </ul>
      <p>
        3.2. Your Personal Information is collected and used because GuardPRO has a legitimate business interest for
        Your Personal Information to be used for the above purposes. This enables GuardPRO to send You relevant and
        personalized content designed to improve Your use of the Services. You have the right to object to this by
        contacting us via email: my.privacy@guard.io. Please note that if you object, this may affect GuardPRO’s ability
        to provide you with the Services and send personalized content to You.
      </p>
      <p>
        3.3. Please note that some of the information You will be requested to provide is necessary for providing You
        the Services. Therefore Your refusal to provide some information may result in You not receiving the Services
        since GuardPRO will not be practically able to provide you with the Services.
      </p>
      <p>
        3.4. We may receive Your information from third parties which we cooperates with (e.g. business partners,
        professional advisors, service providers, etc.).
      </p>
      <h2 class="paragraph-sub-title">4. Storing Information and Mailings</h2>
      <p>
        4.1. Information provided by You will be used in accordance with this Privacy Policy. You hereby agree that such
        information may be stored in GuardPRO’s databases and subject to applicable law will be used also for the
        following purposes:
      </p>
      <p class="l-gap">
        4.1.1. Marketing, advertising, sales promotion, and addressing you by any available media (including in writing,
        by phone, electronic form, and any other form);
      </p>
      <p class="l-gap">
        4.1.2. Encouraging loyalty, research and analyzing of statistics, conducting surveys and any other use regarding
        Your registration and/or subscription to the Services or any part thereof;
      </p>
      <p class="l-gap">4.1.3. Internal uses including investigating complaints and/or reviews;</p>
      <p class="l-gap">
        4.1.4. Operational marketing and statistical purposes including processing information and mailings for such
        purpose;
      </p>
      <p class="l-gap">4.1.5. Providing the Services and additional services to You</p>
      <h2 class="paragraph-sub-title">5. Transferring Information to Third Parties</h2>
      <p>
        GuardPRO may transfer and/or share Your information in the following events and circumstances and, when
        applicable, will give you reasonable notice upon doing so:
      </p>
      <ul>
        <li>
          When You have permitted GuardPRO to transfer and/or share Your information (including by way of permitting
          such transfer via GuardPRO’s Services), and in such case, GuardPRO will always ensure that Your information
          will remain protected and secure when being transferred;
        </li>
        <li>When You violate the Terms or act illegally;</li>
        <li>To enforce the Terms or the Privacy Policy ;</li>
        <li>
          To authorized third party service providers with whom GuardPRO collaborates solely in order to make available
          to you and facilitate your consumption of certain third-party services made accessible through our Services.
          Specifically in connection with Ancillary Identity Theft Insurance Services made accessible via GuardPRO’s
          Services pursuant to the Terms, we may transfer your Information to our Third Party Insurance Service
          Providers (e.g. Sontiq.Inc.) for the purpose of making said Ancillary Identity Theft Insurance Services
          available to you.
        </li>
        <li>
          In response to a subpoena or similar investigative demand, a court order, or a request for cooperation from
          law enforcement or other government agency;
        </li>
        <li>To establish or exercise GuardPRO’s legal rights;</li>
        <li>
          To defend against legal claims; or as otherwise required by law. In such cases, GuardPRO may raise or waive
          any legal objection or right available to GuardPRO, in GuardPRO's sole discretion;
        </li>
        <li>
          When GuardPRO believes disclosure is appropriate in connection with efforts to investigate, prevent, report,
          or take other action regarding illegal activity, suspected fraud, or other wrongdoing;
        </li>
        <li>To protect and defend the rights, property, or safety of GuardPRO, its users, employees, or others;</li>
        <li>To collect any unpaid fees including via collection agencies and attorneys;</li>
        <li>
          In connection with a substantial corporate transaction, such as the sale of GuardPRO’s business, a
          divestiture, merger, consolidation, or asset sale, or in the event of liquidation.
        </li>
      </ul>
      <h2 class="paragraph-sub-title">6. Third-Parties</h2>
      <p>
        6.1. There are few places throughout the Services where You may click on a link to access other websites or
        services that do not operate under this Privacy Policy and are not related to GuardPRO, including, but not only,
        links leading to third-party websites. These links may inter alia independently solicit and collect information,
        including Personal Information, from You and, in some instances, provide GuardPRO with information about Your
        activities on those links.
      </p>
      <p>
        6.2. Every third party such as referred to above may have its own privacy policy describing its use of Personal
        Information, and such policy will regulate the use, handling, and disclosure of such Personal Information of its
        user. Should You require additional information regarding any privacy policy of third parties, GuardPRO
        recommends that You visit these third-party websites and read their privacy policies. It is clarified that such
        third parties unless explicitly mentioned otherwise, are neither controlled nor owned by GuardPRO.
      </p>
      <p>
        6.3. Your information will be handled and used by GuardPRO’s trusted third parties including the following
        recipients in order to deliver the:
      </p>
      <ul>
        <li>
          Providers (including Third Party Insurance Service Providers), partners, and sub-contractors on behalf of
          GuardPRO;
        </li>
        <li>
          Analytics, marketing, performance monitors & search engine providersthat may assist GuardPRO in improving the
          optimization and performance of the Services or any part thereof.
        </li>
      </ul>
      <h2 class="paragraph-sub-title">7. Retaining Your Information</h2>
      <p>
        7.1. GuardPRO will only retain Your Personal Information for as long as necessary to fulfill the purposes it was
        collected for including, but not only, for the purposes of complying with legal, accounting, or reporting
        requirements. The retention period is determined based on the amount, nature, and sensitivity of Your Personal
        Information, the potential risk of harm from unauthorized use or disclosure of Your Personal Information, the
        purposes for which GuardPRO processes Your information, and whether such purposes can be achieved through other
        means with accordance to any applicable law.
      </p>
      <p>
        7.2. To the extent you have chosen to subscribe to and use our SMS messages scanning feature as part of the
        Services, and subject to your consent, we will retain the content of the SMS messages you received from numbers
        not included in your contact list, as of the subscription to such feature and thereafter during your use thereof
        and use such for the sole purpose of improving our Services. Such information will be retained by GuardPRO for
        the minimal period necessary to achieve such a purpose.
      </p>
      <p>
        7.3. Your Personal Information will be retained for as long as you maintain an account with GuardPRO, and as
        necessary to provide you with the Services and as otherwise permitted pursuant to this Privacy Policy.
      </p>
      <p>
        7.4. Your information for marketing communications will be retained unless You will address GuardPRO and request
        that You no longer wish to receive marketing messages from GuardPRO.
      </p>
      <p>
        7.5. If You report any fault regarding Your information, Your details will be retained for a period as necessary
        to protect GuardPRO’s legal rights.
      </p>
      <h2 class="paragraph-sub-title">8. Your Choices About Collection and Use of Your Information and Mailings</h2>
      <p>
        8.1. You can choose not to provide us with certain information, but that may result in You being unable to use
        the Services since such information is necessary in order to enable You to subscribe to and use of the Service
      </p>
      <p>
        8.2. Upon registration to the Service and upon agreeing to receive Mailings, GuardPRO may, with accordance to
        any applicable law, send You Mailings which include email messages and/or newsletters about service updates,
        improvements, special offers, or special content. We may send You other types of transactional and relationship
        email communications, such as service announcements, administrative notices, and surveys. If the we have already
        provided Your information to a third party before You have changed Your preferences or updated Your information,
        You may have to change Your preferences directly with that third party.
      </p>
      <p>
        8.3. It is clarified that at any stage You will have the ability to opt-out of receiving the Mailings by
        contacting us and/or by clicking on a link available on the Mailings sent to You by us.
      </p>
      <h2 class="paragraph-sub-title">9. Use of Cookies</h2>
      <p>
        9.1. GuardPRO uses various technologies to collect information from Your device and about Your activities while
        using the Servicesin order to improve and personalize Your online experience of the Services including by means
        of storing cookies to Your device. Please note that some of the cookies GuardPRO uses are necessary for using
        the Services and/or certain parts thereof and some cookies are used to provide features of third parties.
      </p>
      <p>9.2. GuardPRO uses the following cookies on and as part of the Services:</p>
      <ul>
        <li>
          Necessary Cookies are essential for providing the services of GuardPRO. These cookies do not record any of
          Your identifiable Personal Information and GuardPRO does not need Your consent to place these cookies on Your
          device. Please note that without these cookies, we will not be able to provide You with some or all of the
          Services.
        </li>
        <li>
          Performance cookies are used to collect anonymous information about Your use of the Services or certain parts
          thereof in order to help GuardPRO to improve the Services in whole or in part, including by means of recording
          and tracking webpages You’ve accessed, length of Your visit, Your interaction with a webpage (e.g. mouse
          clicks, scrolling). In some cases, GuardPRO uses trusted third parties to collect such information which may
          include recording Your use of the Services, although it is clarified that such third parties use any of Your
          information solely for the above purpose. By using the Service and/or any part thereof, You agree that
          GuardPRO can place such cookies on Your device. Please note that at any time You have the right to withdraw
          Your consent .
        </li>
        <li>
          Functionality cookies are used to remember Your settings to enhance Your visit to the Services (e.g. textsize,
          language, time zone, or other preferences). The information these cookies collect is anonymous and does not
          enable GuardPRO to track Your browsing activity on while using the Service. By using the Services, or any part
          thereof, You agree that the Operator can place such cookies on Your device. Please note that at any time You
          have the right to withdraw Your consent.
        </li>
        <li>
          Targeting and advertising cookies which are used by GuardPRO’s trusted third parties to display advertisements
          that are relevant to You and measure the effectiveness of such advertisements. Information contained in these
          cookies is anonymous and doesn't contain Your Personal Information. Please note that at any time You have the
          right to withdraw Your consent.
        </li>
        <li>
          Web analytics cookies are used to collect information about how You use and navigate the Services so that
          GuardPRO will be able to add new features and improve Your experience of the Services. This information is
          anonymous and cannot be used to identify you personally.
        </li>
      </ul>
      <p>
        9.3. Please note that if You choose to disable some or all of the above cookies, GuardPRO cannot guarantee the
        proper performance and use of the Services and/or any part thereof and some features may not function properly.
      </p>
      <p>
        9.4. During your use of the Services, third-party websites may store cookies on Your device, e.g. when some
        content of the website is embedded from third-party websites.
      </p>
      <p>
        9.5. Please note that GuardPRO has no control over these cookies and You should check such a thirdparty’s cookie
        policy.
      </p>
      <h2 class="paragraph-sub-title">10. Data Security</h2>
      <p>
        10.1. GuardPRO takes appropriate security measures for data security purposes to help prevent unauthorized
        persons from gaining accessto Your Personal Information. However, no system can be completely secure. Therefore,
        GuardPRO does not warrant that its services will be totally immune to any breaches of security and/or
        unauthorized access, including access to the data stored at or in connection with the Services. In case and upon
        a breach of Your data security GuardPRO will act in accordance with any applicable law, and contact you as
        required thereunder.
      </p>
      <p>
        10.2. GuardPRO inspects, from time to time, the data security of the Services and performs modifications and
        upgrades accordingly in order to keep the Service’s security. Nonetheless, GuardPRO will not be liable for any
        direct or indirect damage caused to You due to the exposure of Your information by unauthorized access to
        GuardPRO’s database and/or due to any negligence or act not controlled by GuardPRO.
      </p>
      <h2 class="paragraph-sub-title">11. Changes to Privacy Policy</h2>
      <p>
        We may occasionally update this Privacy Policy. We will notify you regarding substantial changes to this Privacy
        Policy on the Website homepage or on the Services or we will send you notifications regarding such changes to
        the e-mail address You made available to us. We reserve our rights, at our sole discretion, to amend the Privacy
        Policy at any time. You are responsible to check any updates to the Privacy Policy by entering the Privacy
        Policy from time to time. Your continuation of using the Services following any changes or amendments made to
        this Privacy Policy will be considered as consent to any such amended Privacy Policy.
      </p>
    </div>
  );
};

export default Policy;
