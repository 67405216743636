import { useOutletContext, useSearchParams } from 'react-router-dom';
import GoToQuizA from './goToQuizA';
import GoToQuizB from './goToQuizB';
import { URL_PARAMS } from 'constants';

const GoToQuiz = () => {
  const { incrementStep } = useOutletContext();
  const [searchParams] = useSearchParams();
  const isBScreen = searchParams.get(URL_PARAMS.START_QUIZ_SCREEN) === '2';
  return isBScreen ? <GoToQuizB incrementStep={incrementStep} /> : <GoToQuizA incrementStep={incrementStep} />;
};

export default GoToQuiz;
