import { useEffect } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { PaymentContainer, Section, AccentText, Text } from './ui';
import { Subtitle } from 'components/ui';
import HeadSection from './components/head';
import Seller from './components/seller';
import Trade from './components/trade';
import FeatureAnalysed from './components/featureAnalysed';
import FeatureProtection from './components/featureProtection';
import Guarantee from './components/guarantee';
import Slider from './components/slider';
import Grateful from './components/grateful';
import Review from './components/review';
import Footer from './components/footer';
import PaymentForm from 'components/paymentForm';
import { eventParams, sendPostBack } from 'api/analyticsHelper';
import { URL_PARAMS } from 'constants';

const Container = styled.div`
  width: 100%;
  padding-bottom: 26px;
  color: #fff;
`;

const TextPositioned = styled(Text)`
  margin-bottom: 32px;
`;

const ThirdTitle = styled.div`
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 24px;
`;

const ChoosePlan = () => {
  const { email, currentTariff, onTariffChange, incrementStep, isPaymentInSeparateScreen } = useOutletContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (!isPaymentInSeparateScreen) {
      sendPostBack(eventParams.OPEN_PAYMENT, searchParams.get(URL_PARAMS.CLICK_ID));
    }
  }, []);

  const goToPaymentForm = () => {
    if (!isPaymentInSeparateScreen) {
      document.body.querySelector('#payment-form').scrollIntoView();
      return;
    }
    incrementStep();
  };

  return (
    <Container>
      <PaymentContainer>
        <HeadSection />
        <Seller />
        <Subtitle>Analyzed Your Protection</Subtitle>
        <FeatureAnalysed />
        <Trade currentTariff={currentTariff} onTariffChange={onTariffChange} onGetPlan={goToPaymentForm} />
        <Guarantee />
        {!isPaymentInSeparateScreen && (
          <Section>
            <PaymentForm email={email} incrementStep={incrementStep} currentTariff={currentTariff} />
          </Section>
        )}
        <Subtitle>Grab your Personal Data Protection Plan before it’s gone!</Subtitle>
      </PaymentContainer>
      <Slider />
      <PaymentContainer>
        <Grateful onButtonClick={goToPaymentForm} />

        <Subtitle>
          Decrease data theft risk by <AccentText>x10</AccentText>
        </Subtitle>
        <TextPositioned>
          Guard Pro is constantly evolving, ensuring enhanced protection against leaks. Stay ahead with our cutting-edge
          security features!
        </TextPositioned>

        <ThirdTitle>Get complete protection with:</ThirdTitle>
        <FeatureProtection />

        <Subtitle>Customer Reviews</Subtitle>
        <Review />

        <Footer />
      </PaymentContainer>
    </Container>
  );
};
export default ChoosePlan;
