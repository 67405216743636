import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { supportEmail, ROUTES_NAMES } from 'constants';

const Text = styled.div`
  padding: 12px;
  border-radius: 12px;
  font-size: 10px;
  line-height: 16px;
  font-weight: 500;
  color: #8d92a5;
  background-color: #1e202b;
  margin-top: 16px;
  a {
    color: #ffffff;
    &:hover {
      text-decoration: none;
    }
  }
  p {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const PaymentInfoText = ({ fullPrice }) => {
  return (
    <Text>
      <p>This is an auto renewing subscription.</p>
      <p>
        Today, Guard Pro will charge you {fullPrice} until you cancel the subscription. You can cancel the subscription
        by reaching out to support at  <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
        <br /> Please note that this subscription is bound by Guard Pro{' '}
        <Link target="_blank" rel="noreferrer" to={`/${ROUTES_NAMES.PRIVACY}`}>
          Privacy Policy
        </Link>{' '}
        and{' '}
        <Link target="_blank" rel="noreferrer" to={`/${ROUTES_NAMES.TERMS}`}>
          Terms of Use
        </Link>
        .
      </p>
    </Text>
  );
};

export default PaymentInfoText;
