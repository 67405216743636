import { styled } from 'styled-components';
import guardTop from 'assets/guard-top.svg';
import spotTop from 'assets/spot-top.svg';
import spotRight from 'assets/spot-right.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 32px;
  position: relative;
  height: 100%;
  @media (max-width: 900px) {
    padding: 0 24px 32px;
  }
  & .snackbar {
    width: 90%;
    color: #fff;
    display: flex;
    padding: 6px 16px;
    flex-grow: 1;
    flex-wrap: wrap;
    font-size: 0.875rem;
    align-items: center;
    font-family: 'Inter', 'sofia-pro', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: normal;
    line-height: 1.8;
    border-radius: 4px;
    letter-spacing: normal;
    background-color: rgb(49, 49, 49);
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;
    font-size: 1rem;
    .MuiAlert-icon {
      color: #fff;
    }
    .MuiAlert-message {
      padding-right: 16px;
    }
    .MuiAlert-action {
      cursor: pointer;
      margin-right: 0;
    }
  }
  .MuiFormHelperText-root {
    color: red !important;
  }

  .top-gap {
    display: none;
  }
  @media (min-width: 900px) {
    .top-gap {
      display: block;
      height: 200px;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 12px;
  color: #172335;
  font-size: 1.11rem;
  line-height: 1.67rem;
  font-weight: 800;
`;

export const TitleL = styled.div`
  color: #172335;
  font-size: 1.75rem;
  line-height: 1.995rem;
  font-weight: 700;
  letter-spacing: -0.3px;
  margin-bottom: 12px;
`;

export const Subtitle = styled.div`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.3px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 16px;
`;

export const Text = styled.div`
  font-size: 0.888rem;
  line-height: 1.35rem;
  font-weight: 500;
  letter-spacing: 0.1px;
`;

export const TextL = styled.div`
  font-size: 1.125rem;
  line-height: 1.575rem;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-bottom: 32px;
`;
export const TextPositioned = styled(Text)`
  margin-bottom: 26px;
`;

const DefaultButtonStyles = styled.button`
  flex-shrink: 0;

  border: none;

  text-align: center;
  color: #fff;

  background-color: #2f79e8;
  cursor: pointer;
`;

export const Button = styled(DefaultButtonStyles)`
  width: 100%;
  min-width: 240px;
  padding: 20px 22px;
  border-radius: 16px;

  font-size: 1rem;
  line-height: 30px;
  font-weight: 700;
  /* transition: 0.15s; */
  &:disabled {
    background-color: #a8bfdd;
    opacity: 0.7;
  }
  /* &:hover {
    background-color: #2968c8;
  }
  &:active {
    background-color: #3d6094;
  } */
`;

export const ButtonPayment = styled(Button)`
  padding: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  border-radius: 12px;
  background-image: linear-gradient(to bottom, #ffffff1f, transparent);
  &:disabled {
    background-color: #a8bfdd;
    opacity: 0.7;
  }
`;

export const ButtonBottomPositioned = styled(Button)`
  @media (max-width: 900px) {
    margin-top: auto;
  }
`;

export const ButtonSmall = styled(DefaultButtonStyles)`
  padding: 14px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
  border-radius: 10px;
`;

export const ButtonBottomFixedContainer = styled.div`
  margin-top: auto;
  position: sticky;
  bottom: 0px;
  padding-bottom: 20px;
  margin-bottom: -20px;
  width: 100%;
  background-color: #f5f6ff;
  &::before {
    content: '';
    width: 100%;
    height: 20px;
    position: absolute;
    bottom: 100%;
    background-image: linear-gradient(to top, #f5f6ff, transparent 100%);
  }
`;

export const GuardBackground = styled(Container)`
  background-image: url(${guardTop}), url(${spotTop}), url(${spotRight});
  background-position:
    right 0,
    left 204px,
    right 380px;
  background-repeat: no-repeat;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  z-index: 100000000;
`;

export const OverlayClose = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
  height: 25px;
  background-color: transparent;
  font-size: 0;
  border: 1px solid #81859c;
  border-radius: 50%;
  cursor: pointer;
  transition: border-color 0.15s;
  &::after,
  &::before {
    content: '';
    width: 70%;
    height: 1px;
    border-radius: 3px;
    position: absolute;
    top: 48%;
    left: 15%;
    background-color: #81859c;
    transition: background-color 0.15s;
  }
  &::after {
    transform: rotate(45deg);
  }
  &::before {
    transform: rotate(-45deg);
  }
  &:hover {
    border-color: #61656f;
    &::after,
    &::before {
      background-color: #61656f;
    }
  }
`;
