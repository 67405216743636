import FunnelType from './FunnelType';
import FunnelScreenId from './FunnelScreenId';

const questionnaire = [
  {
    id: FunnelScreenId.regularity,
    type: FunnelType.singleSelect,
    title: 'How often do you change your passwords?',
    isButton: false,
    options: [
      {
        id: '3-6 months',
        text: 'Every 3-6 months',
      },
      {
        id: 'Once a year',
        text: 'Once a year',
      },
      {
        id: 'Occasionally',
        text: 'Only when I learn about a data breach',
      },
    ],
  },
  {
    id: FunnelScreenId.infoRegularity,
    type: FunnelType.text,
    title: 'Security Note:\nChange Your Passwords Regularly',
    text: 'Experts recommend changing your passwords every 3-6 months. Regular updates help protect your personal information from hackers. Use strong, unique passwords for each account to enhance security. Stay safe and keep your data secure!',
    isButton: true,
  },
  {
    id: FunnelScreenId.dataBreaches,
    type: FunnelType.singleSelect,
    title: 'How do you usually find out about data breaches?',
    isButton: false,
    options: [
      {
        id: 'From the news',
        text: 'From the news',
      },
      {
        id: 'When I receive a notification from the affected service',
        text: 'When I receive a notification from the affected service',
      },
      {
        id: "I don't find out about breaches until I run into problems",
        text: "I don't find out about breaches until I run into problems",
      },
    ],
  },
  {
    id: FunnelScreenId.uniqPasswords,
    type: FunnelType.singleSelect,
    title: 'Do you use unique passwords for different sites?',
    isButton: false,
    options: [
      {
        id: 'Yes, a unique one for each site',
        text: 'Yes, a unique one for each site',
      },
      {
        id: 'I use several strong passwords',
        text: "I use several 'strong' passwords",
      },
      {
        id: 'Usually, I use the same password everywhere',
        text: 'Usually, I use the same password everywhere',
      },
    ],
  },
  {
    id: FunnelScreenId.infoUniqPasswords,
    type: FunnelType.text,
    title: 'Security Fact:\nThe Importance of Unique Passwords',
    text: 'Research shows that 81% of data breaches are due to weak or reused passwords. Using unique passwords for different sites significantly reduces the risk of multiple accounts being compromised if one password is exposed. Always create strong, unique passwords to protect your data effectively.',
    isButton: true,
  },
  {
    id: FunnelScreenId.storePasswords,
    type: FunnelType.multipleSelect,
    title: 'How do you store your passwords?',
    isButton: true,
    options: [
      {
        id: 'In a password manager',
        text: 'In a password manager',
      },
      {
        id: 'On paper/in a text file',
        text: 'On paper/in a text file',
      },
      {
        id: 'Memorize them',
        text: 'Memorize them',
      },
    ],
  },
  {
    id: FunnelScreenId.serviceChoose,
    type: FunnelType.singleSelect,
    isButton: false,
    title: 'What do you consider most important when choosing a service to protect against data breaches?',
    options: [
      {
        id: 'Regular notifications about new breaches',
        text: 'Regular notifications about new breaches',
      },
      {
        id: 'Ease of use',
        text: 'Ease of use',
      },
      {
        id: 'Subscription cost',
        text: 'Subscription cost',
      },
    ],
  },
  {
    id: FunnelScreenId.reportsRegularity,
    type: FunnelType.singleSelect,
    isButton: false,
    title: 'How often would you like to receive reports on the security status of your data?',
    options: [
      {
        id: 'Weekly',
        text: 'Weekly',
      },
      {
        id: 'Monthly',
        text: 'Monthly',
      },
      {
        id: 'Upon detecting a new leak',
        text: 'Upon detecting a new leak',
      },
    ],
  },
  {
    id: FunnelScreenId.precautions,
    type: FunnelType.multipleSelect,
    isButton: true,
    title: 'What precautions do you prefer to take to protect your data online?',
    options: [
      {
        id: 'Use two-factor authentication',
        text: 'Use two-factor authentication',
      },
      {
        id: 'Regularly check for data breaches',
        text: 'Regularly check for data breaches',
      },
      {
        id: 'Limit the amount of personal information I share online',
        text: 'Limit the amount of personal information I share online',
      },
    ],
  },
  {
    id: FunnelScreenId.notifications,
    title: 'Which notifications would you like to subscribe to?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Notifications of new data breaches',
        text: 'Notifications of new data breaches',
      },
      {
        id: 'Tips for improving security',
        text: 'Tips for improving security',
      },
      {
        id: 'News about cybersecurity',
        text: 'News about cybersecurity',
      },
    ],
  },
  {
    id: FunnelScreenId.suspiciousEmails,
    title: 'How do you handle suspicious emails or messages from unknown senders?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Immediately delete them without opening',
        text: 'Immediately delete them without opening',
      },
      {
        id: 'Report them as spam or phishing attempts',
        text: 'Report them as spam or phishing attempts',
      },
      {
        id: 'Open cautiously and verify sender identity',
        text: 'Open cautiously and verify sender identity',
      },
      {
        id: "I don't do anything; I ignore them.",
        text: "I don't do anything; I ignore them.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.infoSuspiciousEmails,
    type: FunnelType.text,
    title: 'Recommendation:\nHandling Suspicious Emails',
    text: "If you receive an email or message from an unknown sender, do not open any attachments or click on any links. Instead, mark the message as spam or phishing and delete it immediately. If you're unsure, verify the sender's identity through a separate, trusted communication channel before responding. Always prioritize your security and be cautious with unfamiliar contacts.",
    isButton: true,
  },
  {
    id: FunnelScreenId.protectMeasures,
    title: 'What measures do you take to protect your personal devices from malware and viruses?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Install antivirus software and run regular scans',
        text: 'Install antivirus software and run regular scans',
      },
      {
        id: 'Update software and operating systems promptly',
        text: 'Update software and operating systems promptly',
      },
      {
        id: 'Avoid downloading files or apps from unknown sources',
        text: 'Avoid downloading files or apps from unknown sources',
      },
      {
        id: "I don't take any measures; I leave my devices unprotected.",
        text: "I don't take any measures; I leave my devices unprotected.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.publickWifi,
    title: 'When accessing public Wi-Fi networks, what precautions do you take to safeguard your data?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Use a virtual private network (VPN)',
        text: 'Use a virtual private network (VPN)',
      },
      {
        id: 'Avoid accessing sensitive accounts or information',
        text: 'Avoid accessing sensitive accounts or information',
      },
      {
        id: 'Enable firewall protection on your device',
        text: 'Enable firewall protection on your device',
      },
      {
        id: "I don't take any precautions; I connect without considering the risks.",
        text: "I don't take any precautions; I connect without considering the risks.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.infoPublickWifi,
    type: FunnelType.text,
    title: 'Advice:\nSafeguarding Your Data on Public Wi-Fi',
    text: "Studies reveal that over 70% of public Wi-Fi users are vulnerable to cyberattacks. Use a VPN for encrypted connections and avoid accessing sensitive accounts. Enable your device's firewall and disable file sharing. These precautions enhance security on unsecured networks.",
    isButton: true,
  },
  {
    id: FunnelScreenId.createAccount,
    title: 'What steps do you follow when creating a new online account?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Generate a unique password using a password manager',
        text: 'Generate a unique password using a password manager',
      },
      {
        id: 'Opt for two-factor authentication if available',
        text: 'Opt for two-factor authentication if available',
      },
      {
        id: 'Provide minimal personal information required',
        text: 'Provide minimal personal information required',
      },
      {
        id: "I don't follow any steps; I create accounts without considering security measures.",
        text: "I don't follow any steps; I create accounts without considering security measures.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.softUpdates,
    type: FunnelType.singleSelect,
    isButton: false,
    title: 'How do you respond to security prompts for software updates on your devices?',
    options: [
      {
        id: 'Install updates immediately',
        text: 'Install updates immediately',
      },
      {
        id: 'Schedule updates for later',
        text: 'Schedule updates for later',
      },
      {
        id: "I ignore security prompts; I don't update my software regularly.",
        text: "I ignore security prompts; I don't update my software regularly.",
      },
    ],
  },
  {
    id: FunnelScreenId.securityBreach,
    title: 'In the event of a suspected security breach on one of your accounts, what would you do first?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Change the password for the affected account',
        text: 'Change the password for the affected account',
      },
      {
        id: 'Contact the customer support of the service provider',
        text: 'Contact the customer support of the service provider',
      },
      {
        id: 'Monitor account activity for any unauthorized access',
        text: 'Monitor account activity for any unauthorized access',
      },
      {
        id: "I wouldn't do anything; I ignore security breaches until they cause problems.",
        text: "I wouldn't do anything; I ignore security breaches until they cause problems.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.securityDevice,
    title: 'How do you manage security for your mobile devices?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Enable remote wipe and device location tracking features',
        text: 'Enable remote wipe and device location tracking features',
      },
      {
        id: 'Set up screen lock with PIN, pattern, or biometrics',
        text: 'Set up screen lock with PIN, pattern, or biometrics',
      },
      {
        id: 'Regularly review and uninstall unnecessary apps to minimize security risks',
        text: 'Regularly review and uninstall unnecessary apps to minimize security risks',
      },
      {
        id: 'Other',
        text: 'Other',
      },
      {
        id: "I don't manage security for my mobile devices; I leave them vulnerable.",
        text: "I don't manage security for my mobile devices; I leave them vulnerable.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.personalProtect,
    title: 'What measures do you take to protect your personal information on social media?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Adjust privacy settings to limit who can see my posts',
        text: 'Adjust privacy settings to limit who can see my posts',
      },
      {
        id: 'Avoid sharing sensitive information publicly',
        text: 'Avoid sharing sensitive information publicly',
      },
      {
        id: 'Accept friend requests only from people I know',
        text: 'Accept friend requests only from people I know',
      },

      {
        id: "I don't take any measures to protect my personal information on social media.",
        text: "I don't take any measures to protect my personal information on social media.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.publicProtect,
    title: 'What precautions do you take when using public computers or shared devices?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Avoid logging into personal accounts',
        text: 'Avoid logging into personal accounts',
      },
      {
        id: 'Log out of accounts and clear browsing history after use',
        text: 'Log out of accounts and clear browsing history after use',
      },
      {
        id: 'Use private browsing mode',
        text: 'Use private browsing mode',
      },

      {
        id: "I don't take any precautions; I use public computers or shared devices as if they were my own.",
        text: "I don't take any precautions; I use public computers or shared devices as if they were my own.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.downloadsProtect,
    title: 'What precautions do you take when downloading and installing software or apps?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'Download from official app stores or trusted websites',
        text: 'Download from official app stores or trusted websites',
      },
      {
        id: 'Read user reviews and check app permissions before installation',
        text: 'Read user reviews and check app permissions before installation',
      },
      {
        id: 'Avoid downloading software or apps altogether',
        text: 'Avoid downloading software or apps altogether',
      },

      {
        id: "I don't take any precautions; I download software or apps from any source.",
        text: "I don't take any precautions; I download software or apps from any source.",
        isSingle: true,
      },
    ],
  },
  {
    id: FunnelScreenId.preferNotifications,
    title: 'How would you prefer to receive notifications from our service?',
    type: FunnelType.multipleSelect,
    isButton: true,
    options: [
      {
        id: 'By email',
        text: 'By email',
      },
      {
        id: 'Through a mobile app',
        text: 'Through a mobile app',
      },
      {
        id: 'SMS',
        text: 'SMS',
      },
    ],
  },
];
export default questionnaire;
