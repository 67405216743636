import { useOutletContext } from 'react-router-dom';
import styled from 'styled-components';
import Guard from 'assets/guard-main';
import { Title, Text, Button, GuardBackground } from 'components/ui';
import { ROUTES_NAMES } from 'constants';

const GuardWrapper = styled.div`
  align-self: center;
`;

const ProtectData = () => {
  const { leaksData, incrementStep, isPaymentInSeparateScreen } = useOutletContext();
  const leaksAmount = leaksData?.length || 0;

  const handleClick = () => {
    if (isPaymentInSeparateScreen) {
      incrementStep(ROUTES_NAMES.PAYMENT);
      return;
    }
    incrementStep();
  };

  return (
    <GuardBackground
      style={{
        gap: '20px',
        justifyContent: 'space-between',
      }}
    >
      <div>
        <Title>
          {leaksAmount
            ? `Let's help you fix your ${leaksAmount} data leaks, and keep your identity safe`
            : "Let's help you fix your data leaks, and keep your identity safe."}
        </Title>
        <Text>With Guard Pro you get immediate data leak alerts so you can act fast to protect your identity.</Text>
      </div>
      <GuardWrapper>
        <Guard />
      </GuardWrapper>

      <Button onClick={handleClick}>Protect My Privacy</Button>
    </GuardBackground>
  );
};
export default ProtectData;
