import styled from 'styled-components';

const LeaksItemBlock = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  padding: 8px 15px 8px 8px;
  display: flex;
  align-items: center;
  background-color: #ffebe8;
  border-radius: 12px;
  display: flex;
  flex-shrink: 0;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.15s;
  &:hover {
    background-color: #ffd9d4;
  }
  img {
    padding: 6px;
    width: 40px;
    height: 40px;
    background-color: #fff;
    border-radius: 8px;
    margin-right: 10px;
    object-fit: contain;
    object-position: center;
  }

  div:first-of-type {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
  }
  h3 {
    max-width: 80%;
    color: #172335;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const InfoButton = styled.button`
  flex-shrink: 0;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #ff5d47;
  border: none;
  color: #fff;
  font-weight: 700;
  margin-left: auto;
  cursor: pointer;
`;

const LeaksItem = ({ img, title, onDetails }) => {
  return (
    <LeaksItemBlock
      onClick={() => {
        onDetails();
      }}
    >
      <img src={img} alt="logo" />
      <h3>{title}</h3>
      <InfoButton>i</InfoButton>
    </LeaksItemBlock>
  );
};

export default LeaksItem;
