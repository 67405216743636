import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Lottie from 'react-lottie';
import { Container, TitleL, TextL, Button } from 'components/ui';
import Counter from './counter';
import profileIcon from 'assets/profile.svg';
import animation from './animation.json';
import { DEFAULT_USER_COUNT } from 'constants';

const ContainerBg = styled(Container)`
  position: relative;
  overflow: hidden;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, #ffffff 0%, #ffffff 320px, transparent 70%),
      linear-gradient(to bottom, #fff 0%, transparent 8%), linear-gradient(to right, #ffffff 0%, transparent 25%),
      linear-gradient(to left, #fff 0%, transparent 25%);
    z-index: 1;
  }
`;

const Background = styled.div`
  flex-shrink: 0;
  position: absolute;
  left: 0;
  top: calc(var(--header-height) * -1.55);
  width: 100%;
  z-index: 0;
`;

const Content = styled.div`
  margin-top: auto;
  position: relative;

  z-index: 2;
`;

const Chip = styled.span`
  display: inline-block;
  padding: 4px 12px 4px 16px;
  font-size: 0.75rem;
  line-height: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffffff;

  border-radius: 30px;
`;

const toggle = keyframes`
  0% {
    opacity: 1;
  }
  
  70% {
    opacity: 0;
  }
`;

const ChipRed = styled(Chip)`
  background-color: #fb3743;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 6px;
    top: 10px;
    animation-name: ${toggle};
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
  }
`;

const ChipBlue = styled(Chip)`
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  background-color: #37a9fb;
  position: relative;
  z-index: 1;
  overflow: hidden;
  font-variant-numeric: tabular-nums;
`;

const Header = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
`;

const GoToQuizA = ({ incrementStep }) => {
  const defaultValue = +localStorage.getItem('userCount') || DEFAULT_USER_COUNT;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animation,
    renderer: 'svg',
  };

  return (
    <ContainerBg>
      <Background>
        <Lottie options={defaultOptions} />
      </Background>

      <Content>
        <Header>
          <ChipRed>Live</ChipRed>
          <ChipBlue>
            <Counter defaultCount={defaultValue} maxToAdd={50} />
            <img src={profileIcon} alt="icon" />
          </ChipBlue>
        </Header>

        <TitleL>Secure Your Data with Guard Pro!</TitleL>
        <TextL>A few simple questions will help us to understand your preferences</TextL>
        <Button onClick={incrementStep}>Continue</Button>
      </Content>
    </ContainerBg>
  );
};
export default GoToQuizA;
