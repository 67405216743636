import { initializePaddle } from '@paddle/paddle-js';
import { sendPostBack, eventParams } from 'api/analyticsHelper';
import { ROUTES_NAMES } from 'constants';

const paddleInit = async (token, incrementStep, setPaddleStatus, clickId, isTest) => {
  return await initializePaddle({
    environment: isTest ? 'sandbox' : 'production',
    token: token,
    eventCallback: (e) => {
      console.log('Paddle callback event: ', e);
      if (e.name || e.type === 'checkout.error') {
        setPaddleStatus(e.name || e.type);
      }

      switch (e.name) {
        case 'checkout.loaded':
          sendPostBack(eventParams.OPEN_PADDLE, clickId);
          break;
        case 'checkout.payment.selected':
          sendPostBack(eventParams.CHOOSE_PAYPAL, clickId);
          break;
        case 'checkout.closed':
          return;
        case 'checkout.completed':
          sendPostBack(eventParams.PAYPAL_SUCCESS, clickId);
          incrementStep(ROUTES_NAMES.SUCCESS);
          return;
        default:
          return;
      }
    },
  }).then((paddle) => {
    return paddle;
  });
};

export default paddleInit;
