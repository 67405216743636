import { useEffect } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { ROUTES_NAMES, URL_PARAMS } from 'constants';
import LogoIc from 'assets/logo.svg';

const WrapperContainer = styled.div`
  width: 100%;
  padding-top: var(--header-height);
  height: var(--app-height);
  background: ${({ $background }) => $background || '#F5F6FF'};
`;

const Header = styled.div`
  width: 100%;
  max-width: var(--max-width);
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;

  margin: 0 auto;
  padding: 16px 0;
  line-height: 0;
  img {
    width: 134px;
    height: 36px;
    object-fit: contain;
  }
  @media screen and (max-width: 900px) {
    padding: 14px 24px;
  }
`;

const Form = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  margin: 0 auto;
  overflow-y: auto;
  scroll-behavior: smooth;
`;

const FormContent = styled.div`
  max-width: var(--max-width);
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  @media screen and (max-width: 500px) {
    max-width: 100%;
  }
`;

const getBackground = (routeName, startQuizScreen) => {
  switch (routeName) {
    case ROUTES_NAMES.ENTER_EMAIL:
    case ROUTES_NAMES.PROGRESS:
      return '#fff';
    case ROUTES_NAMES.LEAKS_LIST:
    case ROUTES_NAMES.QUIZ:
    case ROUTES_NAMES.GO_TO_PAY:
    case ROUTES_NAMES.SUCCESS:
      return '#F5F6FF';
    case ROUTES_NAMES.CHOOSE_PLAN:
    case ROUTES_NAMES.PAYMENT:
      return '#010413';
    case ROUTES_NAMES.GO_TO_QUIZ: {
      if (startQuizScreen === '2') {
        return '#F5F6FF';
      }
      return '#ffffff';
    }
    case ROUTES_NAMES.QUIZ_RESULT:
      return 'linear-gradient(to top, #dbdfff 0%, rgba(245, 246, 255, 0) 70%);';
    default:
      return '#F5F6FF';
  }
};

const Wrapper = ({ isHeader, children }) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const currentPath = location.pathname.slice(1).split('/')[0];
  const background = getBackground(currentPath, searchParams.get(URL_PARAMS.START_QUIZ_SCREEN));

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    };

    window.addEventListener('resize', appHeight);
    appHeight();

    const height = isHeader ? document.body.querySelector('#header')?.clientHeight || 64 : 0;
    document.documentElement.style.setProperty('--header-height', `${height}px`);

    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, [location, isHeader]);

  return (
    <WrapperContainer $background={background}>
      {isHeader && (
        <Header id="header">
          <img src={LogoIc} alt="logo" />
        </Header>
      )}
      <Form id="form">
        <FormContent>{children}</FormContent>
      </Form>
    </WrapperContainer>
  );
};

export default Wrapper;
