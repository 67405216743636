import { useEffect, useState, createContext, useMemo } from 'react';
import { Outlet, useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { getConfig } from 'api/api';
import paddleInit from 'api/apiPaddle';
import Wrapper from 'components/wrapper';
import { URL_PARAMS, ROUTES_NAMES, routesInOrder } from 'constants';
import { priceData } from './priceData';

export const ConfigsContext = createContext(null);
const EXPIRED_HOURS = 12;

const clearSavedUserData = () => {
  localStorage.clear('email');
  localStorage.clear('payment');
  localStorage.clear('savedTime');
  localStorage.clear('userCount');
};

function App() {
  const [email, setEmail] = useState('');
  const [currentTariff, setCurrentTariff] = useState(priceData[1]);
  const [leaksData, setLeaksData] = useState(null);
  const [config, setConfig] = useState(null);
  const [paddle, setPaddle] = useState(null);
  const [paddleStatus, setPaddleStatus] = useState('');

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const isPaymentInSeparateScreen = searchParams.get(URL_PARAMS.PAYMENT_IN_SEPARATE_SCREEN) === '1';

  const contextValue = useMemo(() => ({ config, paddle, paddleStatus }), [config, paddle, paddleStatus]);

  const isTest = !!searchParams.get(URL_PARAMS.TEST);
  const clickIdParam = searchParams.get(URL_PARAMS.CLICK_ID);

  const isHeader =
    location.pathname === `/${ROUTES_NAMES.PAYMENT}` || location.pathname === `/${ROUTES_NAMES.CHOOSE_PLAN}`;

  const incrementStep = (routeName) => {
    if (typeof routeName === 'string') {
      navigate({ pathname: routeName, search: searchParams.toString() });
      return;
    }

    const currentRoute = location.pathname.slice(1).split('/')[0];
    if (currentRoute === ROUTES_NAMES.SUCCESS) return;

    const currentIndex = routesInOrder.indexOf(currentRoute);
    const nextRoute =
      routesInOrder[currentIndex + 1] === ROUTES_NAMES.QUIZ
        ? `${routesInOrder[currentIndex + 1]}/1`
        : routesInOrder[currentIndex + 1];
    navigate({ pathname: nextRoute, search: searchParams.toString() });
  };

  useEffect(() => {
    const isPaymentPage = localStorage.getItem('payment'); //сохраняется на экране оплаты, чистится при переходе на formStep === SUCCESS
    const savedEmail = localStorage.getItem('email'); //сохраняется при вводе почты, чистится при переходе на formStep === SUCCESS
    const savedTime = localStorage.getItem('savedTime');

    const timeDiff = Math.floor((new Date().getTime() - savedTime) / 1000 / 60 / 60);

    if (timeDiff > EXPIRED_HOURS || !savedEmail) {
      clearSavedUserData();
      navigate({ pathname: ROUTES_NAMES.ENTER_EMAIL, search: searchParams.toString() });
      return;
    }

    setEmail(savedEmail);

    if (isPaymentPage) {
      //если уже был на странице оплаты не ранее чем EXPIRED_HOURS, попадает снова на нее
      const paymentPage = isPaymentInSeparateScreen ? ROUTES_NAMES.PAYMENT : ROUTES_NAMES.CHOOSE_PLAN;

      navigate({ pathname: paymentPage, search: searchParams.toString() });
    }
  }, []);

  useEffect(() => {
    getConfig(isTest).then((config) => {
      setConfig(config);
    });

    if (clickIdParam) {
      document.cookie = `clickid=${clickIdParam}`;
    }
  }, []);

  useEffect(() => {
    if (!config || paddle) return;
    paddleInit(config.paddle.token, incrementStep, setPaddleStatus, clickIdParam, isTest).then((paddleInstance) => {
      if (paddleInstance) {
        setPaddle(paddleInstance);
      }
    });
  }, [config, paddle]);

  useEffect(() => {
    const currentRoute = location.pathname.slice(1).split('/')[0];
    // пропуск страницы, если указан параметр в url
    if ((searchParams.get(currentRoute) || ROUTES_NAMES.ENTER_EMAIL) === '0') {
      incrementStep();
    }
    if (location.pathname === `/${ROUTES_NAMES.SUCCESS}`) {
      clearSavedUserData();
    }
    document.querySelector('#form').scrollTop = 0;
    // window.scrollTo(0, 0);
  }, [location]);

  return (
    <Wrapper isHeader={!isHeader}>
      <ConfigsContext.Provider value={contextValue}>
        <Outlet
          context={{
            email,
            incrementStep,
            setLeaksData,
            leaksData,
            currentTariff,
            isPaymentInSeparateScreen,
            onEmailChange: setEmail,
            onTariffChange: setCurrentTariff,
          }}
        />
      </ConfigsContext.Provider>
    </Wrapper>
  );
}

export default App;
