import { Link } from 'react-router-dom';
import { ROUTES_NAMES, supportEmail } from 'constants';
import './styles.css';

const Terms = () => {
  return (
    <div class="content">
      <h1 class="title">Terms of use - "GuardPRO"</h1>
      <p class="sub-title">Last modified January 2024</p>
      <br />
      <br />
      <div class="text-body">
        <h2 class="paragraph-sub-title">Introduction</h2>
        <p class="paragraph-text">
          GuardPRO Ltd., a corporation organized under the laws of Israel (“<b>GuardPRO</b>”, “<b>our</b>”, “<b>we</b>”
          or “<b>us</b>”) welcomes you (the “<b>User(s)</b>” and “<b>you</b>”) to: (i) the GuardPRO website located at{' '}
          <Link to={`/${ROUTES_NAMES.TERMS}`}>guardproapp.com/terms</Link> (“<b>Website</b>”); and (ii) to your use of
          our proprietary online solution for identifying malicious cyber-attacks (the “<b>Solution</b>”) and other
          related services made available by GuardPRO from time to time whether through its Website, mobile applications
          or otherwise (“<b>Related Services</b>”) (the Website, the Solution and the Related Services shall be referred
          to collectively hereunder as the “<b>Services</b>”).
        </p>

        <p class="paragraph-text">
          By entering, connecting to, accessing or otherwise using the Services, you acknowledge that you have read and
          understood these Terms of Use (the “<b>Terms of Use</b>”) and our Privacy Policy located at{' '}
          <Link href={`/${ROUTES_NAMES.PRIVACY}`}>guardproapp.com/privacy/</Link> (“<b>Privacy Policy</b>” and together
          with the Terms of Use the “Terms”) and you agree to be bound by the Terms and to comply with all laws and
          regulations that apply to your use of the Services and you agree that these Terms constitute a binding and
          enforceable legal contract between GuardPRO and you. All capitalized terms which are not otherwise defined in
          these Terms of Use shall have the meaning attributed to them in the Privacy Policy. These Terms and any Order
          Forms, exhibits, schedules, addendums, and amendments shall be referred to as the “Agreement”.
        </p>

        <p class="paragraph-text">
          <b>
            ATTENTION - PLEASE READ THE TERMS CAREFULLY BEFORE USING THE SERVICES OR ANY PART THEREOF, AS THEY CONTAIN
            INTER ALIA AN ARBITRATION AGREEMENT AND OTHER IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES,
            AND OBLIGATIONS. THE ARBITRATION AGREEMENT REQUIRES (WITH LIMITED EXCEPTION) THAT YOU SUBMIT CLAIMS YOU HAVE
            AGAINST US TO BINDING AND FINAL ARBITRATION, AND FURTHER SETS THAT (1) YOU WILL ONLY BE PERMITTED TO PURSUE
            CLAIMS AGAINST US ON AN INDIVIDUAL BASIS, NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY CLASS OR REPRESENTATIVE
            ACTION OR PROCEEDING, (2) YOU WILL ONLY BE PERMITTED TO SEEK RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
            DECLARATORY RELIEF) ON AN INDIVIDUAL BASIS, AND (3) YOU MAY NOT BE ABLE TO HAVE ANY CLAIMS YOU HAVE AGAINST
            US RESOLVED BY A JURY OR IN A COURT OF LAW.
          </b>
        </p>

        <p class="paragraph-text">
          IF YOU DO NOT AGREE TO THE TERMS, PLEASE DO NOT ENTER, CONNECT TO, ACCESS, OR USE THE SERVICES OR ANY PART
          THEREOF IN ANY MANNER.
        </p>

        <p class="paragraph-text">
          IF YOU ARE THE FIRST PERSON TO USE OR ACCESS THE SERVICES ON BEHALF OF YOUR ORGANIZATION, YOU HEREBY
          REPRESENT, AGREE, AND ACKNOWLEDGE THAT (I) YOU HAVE BEEN DESIGNATED BY YOUR ORGANIZATION AND HAVE FULL LEGAL
          AUTHORITY TO USE AND REGISTER TO THE SERVICES INCLUDING ON BEHALF OF YOUR ORGANIZATION AND TO BIND YOUR
          ORGANIZATION TO THESE TERMS; AND (II) THESE TERMS ALSO CONSTITUTE A BINDING CONTRACT BETWEEN YOUR ORGANIZATION
          AND GuardPRO; (III) YOUR ORGANIZATION SHALL BEAR ALL RESPONSIBILITY AND LIABILITY TO ANY ACT OR OMISSION OF
          ITS EMPLOYEES, CONTRACTORS OR ANY OTHER THIRD PARTY ON ITS BEHALDF IN VIOLAYION OF THESE TERMS.
        </p>

        <h2 class="paragraph-sub-title">Subscription and License</h2>
        <ol>
          <li>
            In order to subscribe to and use the Solution, User(s) must register through the Website or as otherwise
            directed by GuardPRO, including without limitation download of and registration to GuardPRO mobile
            application(s) (the “Subscription”). To complete the User registration, we will require certain information
            which may will include inter alia your name, e-mail, phone number, password and any other relevant
            information, and may as well require your consent for GuardPRO to receive and process any data and
            information you make available to Us through the Services (“Your Data”). GuardPRO reserves the right to
            decline any Subscription request, for any reason, in its sole discretion. We reserve the right to
            temporarily suspend or permanently terminate your Subscription, at any time at our sole discretion.
          </li>
          <li>
            We may allow you to invite other end users to download and register to GuardPRO Services as end users
            associated with the Your Subscription, all in accordance with the Terms contained herein and any additional
            terms or instructions that may be provided by GuardPRO.
          </li>
          <li>
            Any Subscription will be automatically renewed for additional terms subsequent to the first subscription
            term or as specified in your account, until canceled by the User.
          </li>
          <li>
            GuardPRO may from time to time, offer limited features or functions of the Solution or Related Services for
            a specified period and or jurisdictions, without payment or at a reduced rate to certain users, at
            GuardPRO’s sole discretion.
          </li>
          <li>
            Subject to the timely payment of the Fees and the continuance adherence to these Terms, GuardPRO hereby
            grants User a limited, personal, revocable, non-exclusive, non-transferable, non-sublicensable,
            royalty-free, worldwide right to use the Solution during the Subscription Term and in accordance with the
            scope determined in the applicable Order Form.
          </li>
          <li>
            Please note that for the purpose of and as part of performing the Services, the Solution may change,
            interfere and\or disable certain features, browser plugins, preferences or account settings on third-party
            services.
          </li>
          <li>
            GuardPRO may continuously update its Services, it’s price or any part thereof with new capabilities or
            offerings or replace and/or discontinue some or all of the capabilities without any prior notice or consent.
            You hereby agree that You will not have any claims against GuardPRO in connection with any such change. You
            acknowledge and agree that some of the features and capabilities may be experimental and/or offered in
            limited versions or limited locations. In addition, GuardPRO may at any time, in its sole discretion, add or
            remove supported features and/or capabilities from the Solution. User’s sole remedy in the event of such
            changes shall be, where such changes reflect a material decrease in functionality, to terminate any affected
            subscription to the Solution. In such event User is required to provide GuardPRO with written notice of such
            termination not later than within fourteen (14) days of being notified of such adverse change and GuardPRO
            shall provide User with a refund for the pre-paid un-used subscription fees.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Order Form and Fees</h2>
        <ol>
          <li>
            You may not and you shall not permit any person, and/or any third party to (i) copy, modify, distribute,
            publicly display, transfer or create derivative works of, adapt, emulate, translate, reverse engineer,
            compile, decompile, disassemble or reproduce the Services, or any parts thereof, for any purpose, (ii)
            remove or delete any and all copyright notices, restrictions and signs indicating proprietary rights of
            GuardPRO and/or its licensors, including copyright mark [©] or trademark [® or ™] contained in or
            accompanying the Services or any part thereof, (iii) create a browser or border environment around the
            Services, link, including in-line linking, to elements on the Services, such as images, posters and videos,
            and/or frame or mirror any part thereof or use the Services as a services bureau or otherwise to provide
            services which are in essence similar to the Services to third parties; (iv) transmit, distribute, display
            or otherwise make available through or in connection with the Services any content, including any Your Data
            (as defined above), in a manner which infringes third party rights, including intellectual property rights
            and privacy rights, or which may violate any applicable law or contain and unlawful content; (v) transmit or
            otherwise make available in connection with use of the Services any malware or any other computer code,
            file, or program that may or is intended to damage or hijack the operation of any hardware, software, or
            telecommunications equipment, or any other actually or potentially harmful, disruptive, or invasive code or
            component; (vi) interfere with or disrupt the operation of the Services, or the servers or networks that
            host them or make the Services available; (vii) use the Services for and/or in connection with any illegal
            conduct and/or any form of spam, unsolicited mail or similar conduct; (viii) access and/or use any Services
            and/or the Output or any part thereof in order to build a competitive product or service; (ix) publish or
            disclose to any third party any technical features, quality, performance or benchmark test, or comparative
            analyses relating to the Services; (x) bypass any measures which are used to prevent, control or restrict
            access to the Services and/or certain functionalities therein; (xi) interrupt the activity of the Services
            including, but not limited to, theft of information and breaching the security mechanisms of the Services
            and (xii) infringe or violate any of the terms and conditions of this Agreement. You hereby agree that upon
            GuardPRO’s request, you will immediately return and purge from your systems all materials and copies of the
            same, collected, created or used in breach of these Terms.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">User Warranties</h2>
        <ol>
          <li>
            By using the Services in whole or in part, you represent and warrant that: (i) all registration information
            you submit will be true, accurate, current, and complete; (ii) you will maintain the accuracy of such
            information and promptly update such registration information as necessary; (iii) you have the legal
            capacity and you agree to comply with these Terms; (iv) you are not under the age of 18; (v) you are not a
            minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use
            the Site; (vi) you will not use the Services for any illegal or unauthorized purpose; and (vii) your use of
            the Services will not violate any applicable law or regulation. If you provide any information that is
            untrue, inaccurate, not current, or incomplete, or if you otherwise violate these Terms, we have the right
            to immediately suspend or terminate your Subscription and refuse any and all current or future use of the
            Services (or any portion thereof).
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Intellectual Property</h2>
        <ol>
          <li>
            GuardPRO and its applicable licensors reserve all rights, title, and interest in and to the Services (and
            any part thereof) and any derivatives, improvements, and enhancements thereof, including without limitation
            any and all patent rights, copyright rights, moral rights, rights of publicity, trademark, trade dress, and
            service mark rights, goodwill, trade secret rights and other intellectual property rights therein as may now
            exist or hereafter come into existence.
          </li>
          <li>
            Nothing contained in the Terms, except to the extent explicitly stated herein, will be construed as granting
            You any rights or license in and to the Services or any part thereof.
          </li>
          <li>
            In the event that Users provide GuardPRO with any suggestions, comments, or other feedback relating to
            GuardPRO’s Services (collectively, “Feedback”), such Feedback is provided ‘As Is’ and GuardPRO shall be
            entitled to use the Feedback in its discretion, without any restriction and with no compensation to You and
            You hereby waive any claims regarding GuardPRO’s usage of the Feedback (or part thereof), including, without
            limitation, for moral or economic rights.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Use of Personal Information and Mailings</h2>
        <ol>
          <li>
            GuardPRO respects the privacy of all users of the Services. Information regarding the use of Your
            information can be found in our Privacy Policy.
          </li>
          <li>
            Upon registration, You may be requested to provide certain personal information such as, without limitation
            name, phone number, email address and any other relevant information. Upon your registration you hereby
            consent to receive from GuardPRO (or anyone on its behalf) service notifications, SMS, email messages and/or
            newsletters about updates, improvements, and other user notifications regarding GuardPRO’s Services (the
            “Mailings”).
          </li>
          <li>
            Additionally, during registration, You may be given the choice to accept to receive marketing materials to
            your email and/or mobile phone device (“Marketing Materials”), by checking a checkbox for that purpose . It
            is clarified that at any stage You will have the ability to opt out of receiving the Marketing Materials by
            contacting GuardPRO and/or by clicking on a link available in the applicable Marketing Material message sent
            to You by GuardPRO (or on its behalf).
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Identity Theft Insurance & Restoration Services</h2>
        <ol>
          <li>
            GuardPRO may from time to time, at its sole discretion and in accordance with the applicable laws, offer in
            certain territories, which may vary from time to time the opportunity to access via GuardPRO’s platform
            certain third-party identity theft-related services, which may include identity theft insurance and identity
            restoration services (collectively “Ancillary Identity Theft Services"). GuardPRO may collaborate with one
            or more licensed third-party insurance services provider(s) to offer access to said Ancillary Identity Theft
            Services (“Insurance Service Provider(s)”). The Ancillary Identity Theft Services are underwritten and
            administered by the applicable Insurance Service Provider(s) under a master group policy. A summary of the
            terms of coverage, certificate of insurance, and the complete master policy is provided by Insurance Service
            Provider(s) and, to the extent applicable, for your convenience, are also made available to you through
            GuardPRO’s platform (including a link to download a copy of the master insurance policy and certificate of
            insurance coverage as applicable to the Ancillary Identity Theft Service of your choice) We ask that you
            carefully review the terms governing any Ancillary Identity Theft Services you choose to consume and by
            using any of these Ancillary Identity Theft Services you acknowledge that you have read, understood and
            agree to the relevant terms issued by the applicable Insurance Service Provider.
          </li>
          <li>
            The applicable Insurance Service Provider administers and is responsible for all aspects of the Ancillary
            Identity Theft Services including the insurance or service claims submitted by Users under the Ancillary
            Identity Theft Services, and any requests and other communications related thereto. GuardPRO is not
            affiliated with nor does GuardPRO endorse or recommend any Insurance Service Provider(s), nor the use of one
            or more Ancillary Identity Theft Services. It is entirely your choice whether or not to use one or more
            Ancillary Identity Theft Services. GuardPRO shall have no responsibility or liability with respect to any
            Ancillary Identity Theft Services, or the use thereof.
          </li>
          <li>
            GuardPRO will need to share certain personal information about you with the Identity Theft Service Provider,
            such as your name, email address, and other account information. In addition, your calls, including your
            calls to the Ancillary Identity Theft Services may be recorded by the Identity Theft Service Provider and
            shared with GuardPRO for purposes of provision of the Services and for quality assurance. This is required
            in order to be able to offer you access to Ancillary Identity Theft Services (whether or not you actually
            accessed and/or used such Services). You acknowledge and agree that GuardPRO may share such personal
            information with the Insurance Service Provider for the purpose of and as necessary for facilitating and
            making available the Ancillary Identity Theft Services to you via GuardPRO’s services, all subject to and in
            accordance with GuardPRO’s Privacy Policy. Ancillary Identity Theft Services are also subject to the terms
            and conditions and privacy policy of the Insurance Service Provider which you are urged to carefully read
            prior to using such Services.
          </li>
          <li>
            You agree that in order to use the Ancillary Identity Theft Services you will also be required to
            independently share your information with the Insurance Service Provider(s ) PLEASE NOTE THAT your
            independent or otherwise direct sharing of information with an Insurance Service Provider or anyone on their
            behalf is entirely at your own risk and GuardPRO will not be liable to you or to any other person, in
            connection therewith. By using any of the Ancillary Identity Theft Services you acknowledge that you have
            read, understood, and agreed to all applicable terms issued by the applicable Insurance Service Provider.
          </li>
          <li>
            The description herein is a summary and intended for informational purposes only and does not include all
            terms, conditions, and exclusions of the policies described. Please refer to the actual policies for terms,
            conditions, and exclusions of coverage. The Ancillary Identity Theft Services may not be available in all
            jurisdictions.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Availability</h2>
        <ol>
          <li>
            The Service’s availability and functionality depend on various factors, such as communication networks,
            software, hardware, and GuardPRO’s service providers and contractors. While GuardPRO makes its best
            commercial efforts to make the Services available to you on an ongoing basis, You hereby acknowledge and
            agree that the Services provided hereunder may be interrupted from time to time due to errors that are out
            of the reasonable direct control of GuardPRO and You will not have any claims against GuardPRO in such case.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Warranty Disclaimer</h2>
        <ol>
          <li>
            GuardPRO DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE USE, THE INABILITY TO USE OR OPERATE, OR
            THE OUTPUT OF THE USE OR OPERATION OF THE SERVICES (OR ANY PART THEREOF). THE SERVICES INCLUDING WITHOUT
            LIMITATION ANY OUTPUT, MATERIALS, DATA, AND INFORMATION MADE AVAILABLE THEREHTROUGH OR RELATED THERETO, ARE
            PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
            INCLUDING WARRANTIES OF TITLE, USE, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. GuardPRO AND ITS
            AFFILIATES AND/OR ITS SUBSIDIARIES, INCLUDING ANY OF THEIR RESPECTIVE OFFICERS, DIRECTORS, SHAREHOLDERS,
            EMPLOYEES, SUB-CONTRACTORS, AGENTS, PARENT COMPANIES, SUBSIDIARIES, AND OTHER AFFILIATES (COLLECTIVELY,
            “GuardPRO AFFILIATES”), JOINTLY AND SEVERALLY, DISCLAIM AND MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE
            USABILITY, ACCURACY, QUALITY, AVAILABILITY, RELIABILITY, SUITABILITY, COMPLETENESS, TRUTHFULNESS,
            USEFULNESS, OR EFFECTIVENESS OF ANY OUTPUT, DATA, RESULTS, OR OTHER INFORMATION MADE AVAILABLE, OBTAINED OR
            GENERATED IN CONNECTION WITH YOUR OR ANY USER’S USE OF THE SERVICES. WITHOUT DEROGATING FROM THE GENERALITY
            OF THE FOREGOING, GuardPRO DOES NOT WARRANT THAT THE SERVICES WILL PROVIDE OR DISPLAY ANY WARNINGS OF
            MALICIOUS ACTIVITIES AND/OR ANY OR ALL WARNINGS OF MALICIOUS ACTIVITIES IN A SPECIFIC SERVICE AND/OR
            WEBSITE. IN CASE OF ANY SUSPICIOUS ACTIVITY, YOU ARE ADVISED TO REFRAIN FROM USING THE APPLICABLE SERVICE
            AND/OR WEBSITE.
          </li>
          <li>
            GuardPRO DOES NOT WARRANT THAT THE OPERATION OF THE SERVICES, SOLUTION, AND/OR WEBSITE IS OR WILL BE SECURE,
            ACCURATE, COMPLETE, UNINTERRUPTED, WITHOUT ERROR, OR FREE OF VIRUSES, WORMS, OTHER HARMFUL COMPONENTS, OR
            OTHER PROGRAM LIMITATIONS. YOU AGREE AND ACKNOWLEDGE THAT THE USE OF THE SERVICES, INCLUDING THE USE OF
            AND/OR RELIANCE ON ANY OUTPUT OR OTHER INFORMATION MADE AVAILABLE THROUGH THE SERVICE, IS ENTIRELY, OR
            OTHERWISE TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AT YOUR OWN RISK.
          </li>
          <li>
            The Services provides only a general indication of suspicious websites and services and is not a replacement
            for the User’s independent judgment regarding websites and services accessed and used by the User. GuardPRO
            does not guarantee that any of Your use of the Services will ensure full protection from malicious websites
            and services, and You are aware of the risks of Your use and reliance thereon.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Limitation of Liability</h2>
        <p class="paragraph-text">
          EXCEPT WHERE PROHIBITED BY LAW, IN NO EVENT WILL GuardPRO’S AND/OR ANYONE ON GuardPRO’S BEHALF BE LIABLE FOR
          ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, MULTIPLE OR PUNITIVE, LOSS OR DAMAGE INCLUDING
          WITHOUT LIMITATION LOSS OF PROFITS, LOSS OF BUSINESS, LOSS OF INCOME, LOSS OF CUSTOMERS AND/OR LOST SAVINGS,
          REGARDLESS OF WHETHER THE BASIS OF THE LIABILITY IS BREACH OF CONTRACT, TORT (INCLUDING NEGLIGENCE AND STRICT
          LIABILITY), STATUTES, WARRANTY, STRICT LIABILITY, NEGLIGENCE, MISREPRESENTATIONS OR OTHERWISE, ARISING FROM OR
          IN CONNECTION WITH THE SERVICES OR ANY PART THEREOF.
        </p>
        <p class="paragraph-text">
          IF, NOTWITHSTANDING THE OTHER PROVISIONS OF THESE TERMS, GuardPRO IS FOUND TO BE LIABLE TO YOU FOR ANY DAMAGE
          OR LOSS WHICH ARISES OUT OF OR IS IN ANY WAY CONNECTED WITH YOUR USE OF THE SERVICES OR ANY OUTPUT, GuardPRO’S
          LIABILITY SHALL IN NO EVENT EXCEED THE FEES PAID BY USER TO GuardPRO DURING THE TWELVE 12 MONTHS PERIOD
          IMMEDIATELY PRECEDING THE DATE ON WHICH THE CAUSE OF ACTION AROSE. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS
          OF LIABILITY, SO THE FOREGOING LIMITATION MAY NOT APPLY TO YOU. IN ANY CASE, NO ACTION MAY BE BROUGHT BY YOU
          IN CONNECTION WITH THE SERVICES MORE THAN ONE (1) YEAR AFTER THE ACCRUAL OF SUCH CAUSE OF ACTION.
        </p>
        <p class="paragraph-text">
          YOU HEREBY ACKNOWLEDGE AND AGREE THAT THESE LIMITATIONS OF LIABILITY ARE AGREED ALLOCATIONS OF RISK
          CONSTITUTING IN PART THE CONSIDERATION FOR GuardPRO’S PROVISION OF THE SERVICES TO YOU, AND SUCH LIMITATIONS
          WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY, AND EVEN IF GuardPRO AND/OR
          ANY GUARTIO’S AFFILIATES HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH LIABILITIES AND/OR DAMAGES.
        </p>
        <h2 class="paragraph-sub-title">Indemnifications</h2>
        <p class="paragraph-text">
          You shall indemnify and hold GuardPRO and its predecessors, successors, parents, subsidiaries, affiliates,
          officers, directors, shareholders, investors, employees, agents, representatives, members, and attorneys
          harmless from and against any and all losses, damages, costs, expenses (including attorneys’ fees) and claims
          arising out of or relating to: (i) Your gross negligence or willful misconduct; and/or (ii) Your use of the
          Service, any output provided or otherwise made available in connection therewith or any part of the foregoing;
          and/or (iii) Your Data; and/or (iv) Your breach of these Terms; and/or (v) Any action or inaction by you that
          infringes or misappropriates the intellectual property rights of a third party or violates applicable law.
        </p>
        <h2 class="paragraph-sub-title">Amendments to Terms</h2>
        <p class="paragraph-text">
          GuardPRO may change these Terms from time to time, at its sole discretion and without any notice. We will
          notify you regarding substantial changes to these Terms on the Website homepage or on the Services or we will
          send you notifications regarding such changes to the e-mail address you made available to us. Such substantial
          changes will take effect seven (7) days after such notice was provided on our Website or sent via email.
          Otherwise, all other changes to these Terms are effective as of the stated “Last Revised” date and your
          continued use of the Services after the Last Revised date will constitute acceptance of, and agreement to be
          bound by, those changes.
        </p>
        <h2 class="paragraph-sub-title">Dispute Resolution; Arbitration; Class Action Waiver</h2>
        <ol>
          <li>
            Please read this section carefully. It affects your rights by requiring you to arbitrate disputes with
            GuardPRO and limits the manner in which you can seek relief. It further provides that you waive your rights
            to try any claim in court before a judge or jury and to bring or participate in any class, collective, or
            other representative action. This section of the Terms shall be referred to as the “Arbitration Agreement.”
          </li>
          <li>
            We will try to work in good faith to resolve any issue you have with our Services, if you bring that issue
            to the attention of the GuardPRO team. However, we realize that there may be rare cases where we may not be
            able to resolve an issue to a User's satisfaction. To the extent permitted under the applicable law, You and
            GuardPRO agree that any dispute, claim or controversy arising out of or relating in any way to these Terms
            or your use of the Services and/or any third parties embedded therein shall be determined by binding
            arbitration on an individual basis rather than in court, except that you may assert claims in small claims
            court if your claims qualify, so long as the matter remains in such court and advances only on an individual
            (non-class, non-representative) basis.
          </li>
          <li>
            Arbitration is more informal than bringing a lawsuit in court. Arbitration uses a neutral arbitrator instead
            of a judge or jury and is subject to very limited review by courts. Arbitration allows for more limited
            discovery than in court, however, we agree to cooperate with each other to agree to reasonable discovery in
            light of the issues involved and amount of the claim. Arbitrators can award the same damages and relief that
            a court can award, but in so doing, the arbitrator shall apply substantive law regarding damages as if the
            matter had been brought in court, including without limitation, the law on punitive damages as applied by
            the United States Supreme Court. You agree that, by agreeing to these Terms, the U.S. Federal Arbitration
            Act governs the interpretation and enforcement of this provision, and that you and GuardPRO are each waiving
            the right to a trial by jury or to participate in a class action. This arbitration provision shall survive
            termination of these Terms and any other contractual relationship between you and GuardPRO.
          </li>
          <li>
            BY AGREEING TO ARBITRATION WITH GuardPRO (INCLUDING, WITHOUT LIMITATIONS, IN CONNECTION WITH THE SERVICES),
            YOU ARE AGREEING IN ADVANCE THAT YOU WILL NOT PARTICIPATE IN OR SEEK TO RECOVER MONETARY OR OTHER RELIEF IN
            ANY CLASS, COLLECTIVE, AND/OR REPRESENTATIVE LAWSUIT. INSTEAD, BY AGREEING TO ARBITRATION, YOU MAY BRING
            CLAIMS AGAINST GuardPRO (INCLUDING, WITHOUT LIMITATION, THE SERVICES) ONLY IN AN INDIVIDUAL ARBITRATION
            PROCEEDING.
          </li>
          <li>
            If you desire to assert a claim against GuardPRO, and you therefore elect to seek arbitration, you must
            first send to GuardPRO, by e-mail, a notice of your claim ("Notice"). The Notice to GuardPRO should be
            addressed to: Legal@GuardPROsecurity.com ("Notice Address") and should state in the “subject line” of the
            e-mail “LEGAL NOTICE” in all capital letters. If GuardPRO desires to assert a claim against you and
            therefore elects to seek arbitration, it will send, by certified mail, a written Notice to the most recent
            email address we have on file or otherwise in our records for you. A Notice, whether sent by you or by
            GuardPRO, must (a) describe the nature and basis of the claim or dispute; and (b) set forth the specific
            relief sought ("Demand"). If GuardPRO and you do not reach an agreement to resolve the claim within 30 days
            after the Notice is received, you or GuardPRO may commence an arbitration proceeding or file a claim in
            small claims court. During the arbitration, the amount of any settlement offer made by GuardPRO or you shall
            not be disclosed to the arbitrator. You may download or copy a form Notice and a form to initiate
            arbitration from the American Arbitration Association at www.adr.org The arbitration will be governed by the
            Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes (collectively,
            "AAA Rules") of the American Arbitration Association ("AAA"), as modified by these Terms, and will be
            administered by the AAA.
          </li>
          <li>
            Unless GuardPRO and you agree otherwise, any arbitration hearings will take place in a reasonably convenient
            location for both parties with due consideration of their ability to travel and other pertinent
            circumstances. If the parties are unable to agree on a location, the determination will be made by AAA. If
            your claim is for $10,000 or less, GuardPRO agrees that you may choose whether the arbitration will be
            conducted solely on the basis of documents submitted to the arbitrator, through a telephonic hearing, or by
            an in-person hearing as established by the AAA Rules. If your claim exceeds $10,000, the right to a hearing
            will be determined by the AAA Rules. Regardless of the manner in which the arbitration is conducted, the
            arbitrator will issue a reasoned written decision sufficient to explain the essential findings and
            conclusions on which the award is based.
          </li>
          <li>
            Payment of all filing, administration, and arbitrator fees (collectively, the “Arbitration Fees”) will be
            governed by the AAA Rules, unless otherwise provided in this Arbitration Agreement. To the extent any
            Arbitration Fees are not specifically allocated to either GuardPRO or you under the AAA Rules, GuardPRO and
            you shall split them equally; provided that if you are able to demonstrate to the arbitrator that you are
            economically unable to pay your portion of such Arbitration Fees or if the arbitrator otherwise determines
            for any reason that you should not be required to pay your portion of any Arbitration Fees, GuardPRO will
            pay your portion of such fees. In addition, if you demonstrate to the arbitrator that the costs of
            arbitration will be prohibitive as compared to the costs of litigation, GuardPRO will pay as much of the
            Arbitration Fees as the arbitrator deems necessary to prevent the arbitration from being cost-prohibitive.
            Any payment of attorneys’ fees will be governed by the AAA Rules.
          </li>
          <li>
            All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be
            strictly confidential for the benefit of all parties.
          </li>
          <li>
            YOU AND GuardPRO AGREE THAT EACH MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY,
            AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. Further, unless
            both you and GuardPRO agree otherwise in writing, the arbitrator may not consolidate more than one person's
            claims with your claims and may not otherwise preside over any form of a representative or class proceeding.
            The arbitrator may award declaratory or injunctive relief only in favor of the individual party seeking
            relief and only to the extent necessary to provide relief warranted by that party's individual claim, EXCEPT
            THAT YOU MAY PURSUE A CLAIM FOR AND THE ARBITRATOR MAY AWARD PUBLIC INJUNCTIVE RELIEF UNDER APPLICABLE LAW
            TO THE EXTENT REQUIRED FOR THE ENFORCEABILITY OF THIS PROVISION.
          </li>
          <li>
            If a court of competent jurisdiction or the arbitrator decides that any term or provision of this
            Arbitration Agreement (other than the subsection 13.9 above) is invalid or unenforceable, the parties agree
            to replace such term or provision with a term or provision that is valid and enforceable and that comes
            closest to expressing the intention of the invalid or unenforceable term or provision, and this Arbitration
            Agreement will be enforceable as so modified. If a court or the arbitrator decides that any of the
            provisions of subsection 13.9 above are invalid or unenforceable, then the entirety of this Arbitration
            Agreement will be null and void, unless such provisions are deemed to be invalid or unenforceable solely
            with respect to claims for public injunctive relief. The remainder of these Terms will continue to apply.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">Term and Termination</h2>
        <ol>
          <li>
            These Terms shall come into effect on the earlier of (i) completion of Subscription; or (ii) Your access to
            the Services or any part thereof and shall continue until You cease any use of the Services hereunder unless
            earlier terminated in accordance with these Terms.
          </li>
          <li>
            Without derogating from anything to the contrary stated in these Terms, GuardPRO may at any time,
            immediately terminate any Subscription or other Services provided to You if: (i) You have breached any
            provision of these Terms; and/or (ii) GuardPRO is required to do so by law; and/or (iii) the provision of
            the Service is no longer commercially viable.
          </li>
          <li>We may cancel your Subscription at any time, for any reason, with or without notice.</li>
          <li>
            You may cancel your Subscription at any time by contacting GuardPRO. It is clarified that any such
            cancellation will not entitle You to any refund of any payments that were already paid.
          </li>
        </ol>
        <h2 class="paragraph-sub-title">General Terms</h2>
        <ol>
          <li>
            This Agreement, including the Privacy Policy, constitutes the entire agreement between You and the Operator
            and supersedes all prior and contemporaneous agreements, proposals, or representations, written or oral,
            concerning its subject matter.
          </li>
          <li>
            If You have any inquiries regarding the Terms, You may address the Operator via email{' '}
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </li>
          <li>
            Sections 4, 5, 9, 10, 11, 12, 13 14 and 15 , as well as any other section that is intended by nature to
            survive any termination or expiration of these Terms and/or any applicable Order Form shall survive any
            termination or expiration thereof.
          </li>
          <li>
            If any provision of these Terms is found to be unlawful, void, or for any reason unenforceable, then that
            provision will be deemed severable from these Terms and will not affect the validity and enforceability of
            any remaining provision.
          </li>
          <li>
            No waiver by either party of any breach or default hereunder will be deemed to be a waiver of any preceding
            or subsequent breach or default.
          </li>
          <li>
            This Agreement shall be governed by and construed in accordance with the laws of the State of Israel. Each
            party consents to and agrees that each party is subject to, the exclusive jurisdiction of the courts of Tel
            Aviv, Israel, for any litigation and/or dispute arising out of this Agreement.
          </li>
          <li>
            The Terms constitute the entire agreement between You and GuardPRO and supersede all prior and
            contemporaneous agreements, proposals, or representations, written or oral, concerning its subject matter.
            In the event of any contradiction or inconsistency between the Terms and any content appearing in the
            Services, the provisions of the Terms shall prevail.
          </li>
          <li>
            You hereby agree that a printed version of these Terms and of any notice given in electronic form shall be
            admissible in judicial or administrative proceedings based upon or relating to these Terms to the same
            extent and subject to the same conditions as other business documents and records originally generated and
            maintained in printed form.
          </li>
          <li>
            If You have any questions regarding the Terms and/or Privacy Policy, you may contact the Operator by email:{' '}
            <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default Terms;
