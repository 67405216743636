// https://binomasia.com/click?cnv_id={clickid} - ввод имейла и нажатие continue
// https://binomasia.com/click?cnv_id={clickid}&event1=1 - открытие страницы с результатами сканирования
// https://binomasia.com/click?cnv_id={clickid}&event2=1 - открытие страницы оплаты
// https://binomasia.com/click?cnv_id={clickid}&event3=1 - успешная оплата (edited)
// https://binomasia.com/click?cnv_id={clickid}&event4=1 - открытие paddle
// https://binomasia.com/click?cnv_id={clickid}&event5=1 - юзер выбрал оплату пейпала
// https://binomasia.com/click?cnv_id={clickid}&event6=1 - юзер успешно оплатил пейпалом

export const eventParams = {
  EMAIL_ENTERED: '',
  OPEN_SCAN: '&event1=1',
  OPEN_PAYMENT: '&event2=1',
  PAYMENT_SUCCESS: '&event3=1',
  OPEN_PADDLE: '&event4=1',
  CHOOSE_PAYPAL: '&event5=1',
  PAYPAL_SUCCESS: '&event6=1',
};

export const sendPostBack = async (eventParam = eventParams.EMAIL_ENTERED, clickId) => {
  if (!clickId) return;

  try {
    let img1 = document.createElement('img');
    img1.src = `https://binomasia.com/click?cnv_id=${clickId}${eventParam}`;
    img1.style.cssText = `width:0; height:0; position:absolute; z-index:-5; top: 0; left:0; opacity:0`;
    document.body.appendChild(img1);
  } catch (error) {
    console.log(error);
  }
};
