import { useState, useEffect } from 'react';
import { animate, motion } from 'framer-motion';

const PercentProgress = ({ duration = 7, initialValue = 0 }) => {
  const [counter, setCounter] = useState(75);

  useEffect(() => {
    const controls = animate(initialValue, 100, {
      duration,
      onUpdate(value) {
        setCounter(value.toFixed());
      },
    });
    return () => controls.stop();
  }, []);

  return <motion.span>{counter}%</motion.span>;
};

export default PercentProgress;
