import styled from 'styled-components';
import { motion } from 'framer-motion';

const ProgressWrapper = styled.div`
  width: 100%;
  height: 10px;
  background-color: #e5e6ef;
  border-radius: 20px;
  overflow: hidden;
`;

const Progress = styled(motion.div)`
  width: 0;
  height: 100%;
  background-color: #2f79e8;
`;

const LineProgress = ({ duration = 3, onAnimationComplete }) => {
  return (
    <ProgressWrapper>
      <Progress
        transition={{ duration, ease: 'easeIn' }}
        animate={{
          width: '100%',
        }}
        onAnimationComplete={onAnimationComplete}
      />
    </ProgressWrapper>
  );
};

export default LineProgress;
