import styled from 'styled-components';
import { Section } from '../../ui';
import security from './assets/security.svg';
import wallet from './assets/wallet.svg';
import user from './assets/user.svg';
import web from './assets/web.svg';

const Block = styled(Section)`
  border-radius: 12px;
`;

const BlockRow = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const BlockItem = styled.div`
  width: 50%;
  flex-shrink: 0;
  padding: 24px 16px;
  border-radius: 12px;
  background-color: #1e202b;
`;

const Picture = styled.img`
  margin-bottom: 15px;
`;

const Text = styled.div`
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #ffffff;
`;

const FeatureProtection = () => {
  return (
    <Block>
      <BlockRow>
        <BlockItem>
          <Picture src={user} />
          <Text>Get rid of the stress and worry of having your email hacked</Text>
        </BlockItem>
        <BlockItem>
          <Picture src={wallet} />
          <Text>Secure your financial information to prevent theft.</Text>
        </BlockItem>
      </BlockRow>
      <BlockRow>
        <BlockItem>
          <Picture src={security} />
          <Text>Protect your personal data and confidential information from hackers</Text>
        </BlockItem>
        <BlockItem>
          <Picture src={web} />
          <Text>Manage access to your email account and track suspicious activity</Text>
        </BlockItem>
      </BlockRow>
    </Block>
  );
};

export default FeatureProtection;
