import { useEffect, useRef, useState } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { eventParams, sendPostBack } from 'api/analyticsHelper';
import { Container, Title, Button, Overlay, ButtonBottomFixedContainer, OverlayClose } from 'components/ui';
import AccordionItem from 'components/accordionItem';
import LeaksItem from './leaksItem';
import PicThreadEmail from 'assets/thread-email.svg';
import PicThreadBrowser from 'assets/thread-browser.svg';
import PicThreadData from 'assets/thread-data.svg';
import PicThreadSms from 'assets/thread-sms.svg';
import IconArrow from 'assets/icon-arrow-right-button.svg';

import noLeaksData from './noLeaksData';
import { URL_PARAMS } from 'constants';

const ContainerWithHeight = styled(Container)`
  min-height: 550px;
  align-items: stretch;
`;

const LeaksHeader = styled.div`
  margin-bottom: 32px;
  text-align: center;
`;

const LeaksTitle = styled(Title)`
  color: #172335;
  margin-bottom: 9px;
`;

const LeaksText = styled.p`
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #5d6572;
`;

const ThreadsAmount = styled.span`
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 6px;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  font-weight: bold;
  background-color: #ff5d47;
  border-radius: 50%;
  color: #fff;
`;

const ContentList = styled.div`
  margin-bottom: 20px;
  & > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const LeaksContainer = styled.div`
  height: 215px;
  overflow-y: auto;
  width: 100%;

  position: relative;
  overflow-y: auto;
  & > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &::after {
    content: '';
    position: sticky;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;

    margin-top: auto;
    background-image: linear-gradient(to top, #ffffff 0%, transparent 70%);
    height: 10px;
    pointer-events: none;
  }
  &::before {
    content: '';
    position: sticky;
    top: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 100%;

    margin-top: auto;
    background-image: linear-gradient(to bottom, #ffffff 0%, transparent 70%);
    height: 12px;
    pointer-events: none;
  }
  &.leaks-veil-visible::after {
    min-height: 150px;
    opacity: 1;
  }
  @media (min-width: 900px) {
    overflow-y: scroll;
    margin-bottom: 16px;
  }
`;

const ShowMoreButtonContainer = styled.div`
  text-align: center;
`;

const ShowMoreButton = styled.button`
  text-align: center;
  color: #2f79e8;
  background-color: transparent;
  border: none;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  @keyframes moveDown {
    10% {
      transform: translateY(-2px);
    }
    50% {
      transform: translateY(4px);
    }
    100% {
      transform: translateY(0);
    }
  }
  span {
    display: inline-block;
    transform: translateY(0);
    transition: 0.5s;
  }
  &:hover {
    color: #3d6095;
    span {
      /* transform: translateY(5px); */
      animation-name: moveDown;
      animation-duration: 0.5s;
    }
  }
`;

const ButtonText = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const OverlayContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 600px;
  border-radius: 12px;
  background: #fff;
  padding: 22px 20px;
  display: flex;
  flex-direction: column;
  max-height: 85%;
  overflow-y: auto;
`;

const OverlayHeader = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-bottom: 30px;
  color: #172335;
  & img {
    width: 30px;
    height: 30px;
    object-fit: contain;
    object-position: center;
  }
  & p {
    font-weight: 600;
    font-size: 1rem;
  }
`;
const OverlayTitle = styled.span`
  padding-right: 30px;
  font-size: 1.11rem;
  font-weight: 700;
  color: #172335;
  word-break: break-word;
`;

const OverlayText = styled.div`
  margin-bottom: 18px;

  & h3 {
    font-size: 0.9rem;
    color: #172335;
    font-weight: 700;
    margin-bottom: 4px;
  }
  & p {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    color: #5d6572;
  }
`;

const LeaksList = () => {
  const { leaksData, incrementStep } = useOutletContext();
  const [isDetails, setIsDetails] = useState(false);
  const [currentId, setCurrentId] = useState(0);
  const [amountToShow, setAmountToShow] = useState(3);
  const leaksListRef = useRef(null);
  const [searchParams] = useSearchParams();

  const overlayData = leaksData ? leaksData[currentId] : noLeaksData[currentId];

  useEffect(() => {
    leaksListRef.current.scrollTo({ top: leaksListRef.current.scrollHeight, behavior: 'smooth' });
  }, [amountToShow]);

  useEffect(() => {
    sendPostBack(eventParams.OPEN_SCAN, searchParams.get(URL_PARAMS.CLICK_ID));
  }, []);

  const renderDetails = () => {
    return (
      <Overlay
        onClick={() => {
          setIsDetails(false);
        }}
      >
        <OverlayContent
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <OverlayClose
            onClick={() => {
              setIsDetails(false);
            }}
          />
          <OverlayHeader>
            <img src={overlayData.LogoPath} alt="logo" />
            <OverlayTitle>{overlayData.Title}</OverlayTitle>
          </OverlayHeader>
          <OverlayText>
            <h3>Affected Users</h3>
            <p>{overlayData.PwnCount}</p>
          </OverlayText>

          <OverlayText>
            <h3>Compromised Data</h3>
            <p dangerouslySetInnerHTML={{ __html: overlayData.Description }}></p>
          </OverlayText>
        </OverlayContent>
      </Overlay>
    );
  };

  const isDataFound = !!leaksData?.length;
  const dataList = isDataFound ? leaksData : noLeaksData;

  const setShowMore = () => {
    if (amountToShow >= dataList.length - 1) return;
    setAmountToShow((oldAmount) => oldAmount + 3);
  };

  return (
    <ContainerWithHeight>
      {isDetails && renderDetails()}
      <LeaksHeader>
        <LeaksTitle>
          You have <ThreadsAmount>4</ThreadsAmount> security threats
        </LeaksTitle>
        <LeaksText>Let’s see where you’re at risk</LeaksText>
      </LeaksHeader>

      <ContentList>
        <AccordionItem
          title="Data leaks"
          icon={PicThreadData}
          content={{
            title: isDataFound ? `We found your data in ${leaksData.length} leaks` : `No Data Leaks Were Found`,
            text: isDataFound ? (
              ''
            ) : (
              <>
                We searched for your personal info in 32,284 data breaches.
                <br /> Leaks we've found for others like you:
              </>
            ),
          }}
        >
          <LeaksContainer ref={leaksListRef}>
            {dataList.slice(0, amountToShow).map((e, i) => (
              <LeaksItem
                key={i}
                img={e.LogoPath}
                title={e.Title}
                onDetails={() => {
                  setCurrentId(i);
                  setIsDetails(true);
                }}
              />
            ))}
          </LeaksContainer>
          {dataList.length - 1 > amountToShow && (
            <ShowMoreButtonContainer>
              <ShowMoreButton onClick={setShowMore}>
                Show 3 more leaks <span>↓</span>
              </ShowMoreButton>
            </ShowMoreButtonContainer>
          )}
        </AccordionItem>
        <AccordionItem
          title="Email security"
          icon={PicThreadEmail}
          content={{
            title: 'Your inbox is vulnerable to phishing',
            text: 'Hackers can sneak scam emails past a spam filter right into an unprotected inbox. In 2023 there were over 500M phishing attacks in the US.',
          }}
        />
        <AccordionItem
          title="SMS phishing"
          icon={PicThreadSms}
          content={{
            title: "You're not protected from text scams",
            text: '68 million Americans fell for a text message scam last year. You’re not currently protected from malicious text messages.',
          }}
        />
        <AccordionItem
          title="Browsing protection"
          icon={PicThreadBrowser}
          content={{
            title: 'You’re exposed to malicious sites',
            text: 'Being online without protection leaves you wide open to cybercriminals. Last year, 33B online accounts were hacked.',
          }}
        />
      </ContentList>
      <ButtonBottomFixedContainer>
        <Button
          onClick={() => {
            incrementStep();
          }}
        >
          <ButtonText>
            Continue <img src={IconArrow} alt="" />
          </ButtonText>
        </Button>
      </ButtonBottomFixedContainer>
    </ContainerWithHeight>
  );
};

export default LeaksList;
