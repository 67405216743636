import styled from 'styled-components';
import Promo from './promo';
import TariffItem from 'components/tariffItem';
import PaymentInfoText from 'components/paymentInfoText';
import { ButtonPayment, Subtitle } from 'components/ui';
import { priceData } from 'priceData';

const TradeBlock = styled.div`
  margin-bottom: 20px;
  scroll-margin: 16px;
`;

const LinkContainer = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Link = styled.span`
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #babdc9;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

const TariffContainer = styled.div`
  margin-bottom: 16px;
`;

const Trade = ({ currentTariff, onTariffChange, onGetPlan }) => {
  const goToBlock = () => {
    document.querySelector('#guarantee').scrollIntoView();
  };

  return (
    <TradeBlock id="tariff">
      <Subtitle>
        Grab your Personal Data
        <br />
        Protection Plan before it’s gone!
      </Subtitle>
      <Promo />
      <TariffContainer>
        {priceData.map((item) => {
          return <TariffItem key={item.id} onChange={onTariffChange} tariff={item} currentTariff={currentTariff} />;
        })}
      </TariffContainer>
      <LinkContainer>
        <Link onClick={goToBlock}>30 day money back guarantee</Link>
      </LinkContainer>
      <ButtonPayment onClick={onGetPlan}>Get My Plan</ButtonPayment>
      <PaymentInfoText fullPrice={currentTariff.fullPrice} />
    </TradeBlock>
  );
};
export default Trade;
