import { useEffect } from 'react';
import { useOutletContext, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { eventParams, sendPostBack } from 'api/analyticsHelper';
import { Container, Subtitle } from 'components/ui';
import TariffItem from 'components/tariffItem';
import PaymentForm from 'components/paymentForm';
import Timer from 'components/timer';
import { URL_PARAMS } from 'constants';
import { priceData } from 'priceData';

const PaymentContainer = styled(Container)`
  padding-top: 80px;
  color: #fff;
`;

const SaleBanner = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 20px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  background-color: rgb(39, 174, 96);
  z-index: 10;
`;

const TariffItemContainer = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const TimerContainer = styled.span`
  display: inline-block;
  width: 40px;
  font-size: 16px;
  font-weight: bold;
  margin-left: 4px;
`;

const FormContainer = styled.div`
  padding-bottom: 20px;
`;

const PaymentPage = () => {
  const { email, currentTariff, incrementStep, onTariffChange } = useOutletContext();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    localStorage.setItem('payment', true);
    localStorage.setItem('savedTime', new Date().getTime());
    sendPostBack(eventParams.OPEN_PAYMENT, searchParams.get(URL_PARAMS.CLICK_ID));
  }, []);

  return (
    <PaymentContainer>
      <SaleBanner>
        Discount reserved for{' '}
        <TimerContainer>
          <Timer delayMinutes={10} theme="inline" />
        </TimerContainer>
      </SaleBanner>
      <Subtitle>Billing Cycle</Subtitle>
      <TariffItemContainer>
        {priceData.map((item) => {
          return <TariffItem key={item.id} onChange={onTariffChange} tariff={item} currentTariff={currentTariff} />;
        })}
      </TariffItemContainer>
      <FormContainer>
        <PaymentForm email={email} incrementStep={incrementStep} currentTariff={currentTariff} />
      </FormContainer>
    </PaymentContainer>
  );
};

export default PaymentPage;
