export const Guard = () => (
  <svg width="193" height="228" viewBox="0 0 193 228" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1_62)">
      <path
        d="M168.374 40.4757L98.1815 17.0782C96.9765 16.6775 95.6838 16.6775 94.4788 17.0782L24.2861 40.4757C21.8996 41.2713 20.2881 43.5057 20.2881 46.0239V127.915C20.2881 178.346 91.4517 202.647 94.4788 203.656C95.0813 203.858 95.7043 203.957 96.3301 203.957C96.956 203.957 97.579 203.858 98.1815 203.656C101.209 202.647 172.372 178.346 172.372 127.915V46.0239C172.372 43.5057 170.761 41.2713 168.374 40.4757Z"
        fill="url(#paint0_linear_1_62)"
      />
      <path
        d="M67.0649 105.561L88.8613 129.077L127.899 86.9611"
        stroke="white"
        strokeWidth="14.0385"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1_62"
        x="0.28125"
        y="0.777679"
        width="192.091"
        height="227.222"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="10" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.223529 0 0 0 0 0.360784 0 0 0 0 0.572549 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1_62" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1_62" result="shape" />
      </filter>
      <linearGradient
        id="paint0_linear_1_62"
        x1="31.4107"
        y1="162.327"
        x2="161.255"
        y2="32.4796"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0099" stopColor="#2A4D89" />
        <stop offset="1" stopColor="#688BAD" />
      </linearGradient>
    </defs>
  </svg>
);
export default Guard;
